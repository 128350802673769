import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Step, StepLabel, Stepper } from "@mui/material";

import { useAppSelector } from "../../store/hook";

import Step1 from "../../components/connect/Step1";
import Step2 from "../../components/connect/Step2";
import Step3 from "../../components/connect/Step3";

import "./RegistrationSteps.css";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  title: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, title, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="registration-content">
          <h2 className="registration-title">{title}</h2>
          {children}
        </div>
      )}
    </div>
  );
}

const RegistrationSteps = () => {
  const userRegistration = useAppSelector(
    (state) => state.auth.userRegistration
  );

  const { step } = useParams();
  const currentStep = parseInt(step?.split("-")?.[1] ?? "1") - 1;

  const [activeStep, setActiveStep] = useState(currentStep);

  useEffect(() => {
    setActiveStep(currentStep);
  }, [currentStep]);

  return (
    <div className="registration-main">
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel />
        </Step>
        <Step>
          <StepLabel />
        </Step>
        <Step>
          <StepLabel />
        </Step>
      </Stepper>

      <div>
        <CustomTabPanel
          index={0}
          value={activeStep}
          title="Informations complémentaires"
        >
          <Step1 userRegistration={userRegistration} />
        </CustomTabPanel>
        <CustomTabPanel index={1} value={activeStep} title="Profession">
          <Step2 userRegistration={userRegistration} />
        </CustomTabPanel>
        <CustomTabPanel
          index={2}
          value={activeStep}
          title="Vérification de votre email"
        >
          <Step3 />
        </CustomTabPanel>
      </div>
    </div>
  );
};

export default RegistrationSteps;
