export type UserRegistrationBase = {
  email: string;
  password: string;
};

export type UserRegistrationStep1 = {
  title: UserTitle;
  firstname: string;
  lastname: string;
  birthdate?: string;
  country: UserCountry;
};

export type UserRegistrationStep2 = {
  speciality: UserSpeciality;
  establishment: string;
  service: string;
  city: string;
};

export type UserRegistration = UserRegistrationBase &
  UserRegistrationStep1 &
  UserRegistrationStep2;

export enum UserTitle {
  MR = "MR",
  MS = "MS",
}

export enum UserSpeciality {
  "Anesthésiste-réanimateur" = "Anesthésiste-réanimateur",
  "Biologiste" = "Biologiste",
  "Cardiologue" = "Cardiologue",
  "Chirurgien" = "Chirurgien",
  "Étudiant" = "Étudiant",
  "Gastro-entérologue" = "Gastro-entérologue",
  "Gériatre" = "Gériatre",
  "Généraliste" = "Généraliste",
  "Hygiéniste" = "Hygiéniste",
  "Interne MIT" = "Interne MIT",
  "Interne toutes spécialités" = "Interne toutes spécialités",
  "Maladies infectieuses" = "Maladies infectieuses",
  "Médecine interne" = "Médecine interne",
  "Microbiologiste" = "Microbiologiste",
  "Néphrologue" = "Néphrologue",
  "Oncologue" = "Oncologue",
  "Pédiatre" = "Pédiatre",
  "Pharmacien" = "Pharmacien",
  "Pneumologue" = "Pneumologue",
  "Médecine physique et réadaptation" = "Médecine physique et réadaptation",
  "Médecin réanimateur" = "Médecin réanimateur",
  "Médecine d’urgences" = "Médecine d’urgences",
  "Autre..." = "Autre...",
}

export enum UserCountry {
  AFGHANISTAN = "Afghanistan",
  ALBANIA = "Albanie",
  ALGERIA = "Algérie",
  ANDORRA = "Andorre",
  ANGOLA = "Angola",
  ANTIGUA_AND_BARBUDA = "Antigua et Barbuda",
  ARGENTINA = "Argentine",
  ARMENIA = "Arménie",
  AUSTRALIA = "Australie",
  AUSTRIA = "Autriche",
  AZERBAIJAN = "Azerbaïdjan",
  BAHAMAS = "Bahamas",
  BAHRAIN = "Bahreïn",
  BANGLADESH = "Bangladesh",
  BARBADOS = "Barbade",
  BELARUS = "Bélarus",
  BELGIUM = "Belgique",
  BELIZE = "Belize",
  BENIN = "Bénin",
  BHUTAN = "Bhoutan",
  BOLIVIA = "Bolivie",
  BOSNIA_AND_HERZEGOVINA = "Bosnie-Herzégovine",
  BOTSWANA = "Botswana",
  BRAZIL = "Brésil",
  BRUNEI = "Brunéi",
  BULGARIA = "Bulgarie",
  BURKINA_FASO = "Burkina Faso",
  BURUNDI = "Burundi",
  CABO_VERDE = "Cap-Vert",
  CAMBODIA = "Cambodge",
  CAMEROON = "Cameroun",
  CANADA = "Canada",
  CENTRAL_AFRICAN_REPUBLIC = "République centrafricaine",
  CHAD = "Tchad",
  CHILE = "Chili",
  CHINA = "Chine",
  COLOMBIA = "Colombie",
  COMOROS = "Comores",
  CONGO = "Congo",
  COSTA_RICA = "Costa Rica",
  CROATIA = "Croatie",
  CUBA = "Cuba",
  CYPRUS = "Chypre",
  CZECH_REPUBLIC = "République tchèque",
  DENMARK = "Danemark",
  DJIBOUTI = "Djibouti",
  DOMINICA = "Dominique",
  DOMINICAN_REPUBLIC = "République dominicaine",
  EAST_TIMOR = "Timor oriental",
  ECUADOR = "Équateur",
  EGYPT = "Égypte",
  EL_SALVADOR = "Salvador",
  EQUATORIAL_GUINEA = "Guinée équatoriale",
  ERITREA = "Érythrée",
  ESTONIA = "Estonie",
  ESWATINI = "Eswatini",
  ETHIOPIA = "Éthiopie",
  FIJI = "Fidji",
  FINLAND = "Finlande",
  FRANCE = "France",
  GABON = "Gabon",
  GAMBIA = "Gambie",
  GEORGIA = "Géorgie",
  GERMANY = "Allemagne",
  GHANA = "Ghana",
  GREECE = "Grèce",
  GRENADA = "Grenade",
  GUATEMALA = "Guatemala",
  GUINEA = "Guinée",
  GUINEA_BISSAU = "Guinée-Bissau",
  GUYANA = "Guyana",
  HAITI = "Haïti",
  HONDURAS = "Honduras",
  HUNGARY = "Hongrie",
  ICELAND = "Islande",
  INDIA = "Inde",
  INDONESIA = "Indonésie",
  IRAN = "Iran",
  IRAQ = "Irak",
  IRELAND = "Irlande",
  ISRAEL = "Israël",
  ITALY = "Italie",
  IVORY_COAST = "Côte d'Ivoire",
  JAMAICA = "Jamaïque",
  JAPAN = "Japon",
  JORDAN = "Jordanie",
  KAZAKHSTAN = "Kazakhstan",
  KENYA = "Kenya",
  KIRIBATI = "Kiribati",
  KOREA_NORTH = "Corée du Nord",
  KOREA_SOUTH = "Corée du Sud",
  KOSOVO = "Kosovo",
  KUWAIT = "Koweït",
  KYRGYZSTAN = "Kirghizistan",
  LAOS = "Laos",
  LATVIA = "Lettonie",
  LEBANON = "Liban",
  LESOTHO = "Lesotho",
  LIBERIA = "Libéria",
  LIBYA = "Libye",
  LIECHTENSTEIN = "Liechtenstein",
  LITHUANIA = "Lituanie",
  LUXEMBOURG = "Luxembourg",
  MADAGASCAR = "Madagascar",
  MALAWI = "Malawi",
  MALAYSIA = "Malaisie",
  MALDIVES = "Maldives",
  MALI = "Mali",
  MALTA = "Malte",
  MARSHALL_ISLANDS = "Îles Marshall",
  MAURITANIA = "Mauritanie",
  MAURITIUS = "Maurice",
  MEXICO = "Mexique",
  MICRONESIA = "Micronésie",
  MOLDOVA = "Moldavie",
  MONACO = "Monaco",
  MONGOLIA = "Mongolie",
  MONTENEGRO = "Monténégro",
  MOROCCO = "Maroc",
  MOZAMBIQUE = "Mozambique",
  MYANMAR = "Myanmar",
  NAMIBIA = "Namibie",
  NAURU = "Nauru",
  NEPAL = "Népal",
  NETHERLANDS = "Pays-Bas",
  NEW_ZEALAND = "Nouvelle-Zélande",
  NICARAGUA = "Nicaragua",
  NIGER = "Niger",
  NIGERIA = "Nigéria",
  NORTH_MACEDONIA = "Macédoine du Nord",
  NORWAY = "Norvège",
  OMAN = "Oman",
  PAKISTAN = "Pakistan",
  PALAU = "Palaos",
  PALESTINE = "Palestine",
  PANAMA = "Panama",
  PAPUA_NEW_GUINEA = "Papouasie-Nouvelle-Guinée",
  PARAGUAY = "Paraguay",
  PERU = "Pérou",
  PHILIPPINES = "Philippines",
  POLAND = "Pologne",
  PORTUGAL = "Portugal",
  QATAR = "Qatar",
  ROMANIA = "Roumanie",
  RUSSIA = "Russie",
  RWANDA = "Rwanda",
  SAINT_KITTS_AND_NEVIS = "Saint-Kitts-et-Nevis",
  SAINT_LUCIA = "Sainte-Lucie",
  SAINT_VINCENT_AND_THE_GRENADINES = "Saint-Vincent-et-les-Grenadines",
  SAMOA = "Samoa",
  SAN_MARINO = "Saint-Marin",
  SAO_TOME_AND_PRINCIPE = "Sao Tomé-et-Principe",
  SAUDI_ARABIA = "Arabie saoudite",
  SENEGAL = "Sénégal",
  SERBIA = "Serbie",
  SEYCHELLES = "Seychelles",
  SIERRA_LEONE = "Sierra Leone",
  SINGAPORE = "Singapour",
  SLOVAKIA = "Slovaquie",
  SLOVENIA = "Slovénie",
  SOLOMON_ISLANDS = "Îles Salomon",
  SOMALIA = "Somalie",
  SOUTH_AFRICA = "Afrique du Sud",
  SOUTH_SUDAN = "Soudan du Sud",
  SPAIN = "Espagne",
  SRI_LANKA = "Sri Lanka",
  SUDAN = "Soudan",
  SURINAME = "Suriname",
  SWEDEN = "Suède",
  SWITZERLAND = "Suisse",
  SYRIA = "Syrie",
  TAIWAN = "Taïwan",
  TAJIKISTAN = "Tadjikistan",
  TANZANIA = "Tanzanie",
  THAILAND = "Thaïlande",
  TOGO = "Togo",
  TONGA = "Tonga",
  TRINIDAD_AND_TOBAGO = "Trinité-et-Tobago",
  TUNISIA = "Tunisie",
  TURKEY = "Turquie",
  TURKMENISTAN = "Turkménistan",
  TUVALU = "Tuvalu",
  UGANDA = "Ouganda",
  UKRAINE = "Ukraine",
  UNITED_ARAB_EMIRATES = "Émirats arabes unis",
  UNITED_KINGDOM = "Royaume-Uni",
  UNITED_STATES = "États-Unis",
  URUGUAY = "Uruguay",
  UZBEKISTAN = "Ouzbékistan",
  VANUATU = "Vanuatu",
  VATICAN_CITY = "Vatican",
  VENEZUELA = "Venezuela",
  VIETNAM = "Vietnam",
  YEMEN = "Yémen",
  ZAMBIA = "Zambie",
  ZIMBABWE = "Zimbabwe",
}
