import { useEffect, useState } from "react";

import api from "../../utils/api/axios";
import { ComityMember, ReduceMembers } from "../../types/comity";

import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";
import Section from "../../components/comity/Section";

import "./Comity.css";

const Comity = () => {
  const title = "Comité editorial";

  const [coordinators, setCoordinators] = useState<ComityMember[]>();
  const [comityMembers, setComityMembers] = useState<ComityMember[]>();

  const fetchData = async () => {
    try {
      const { data } = await api.get<ComityMember[]>(`/comity-members`);

      const dataReduced = data.reduce(
        (acc, member) => {
          const siteNameSplit = member.siteName.split("|");

          if (siteNameSplit.includes("COMITY_MEMBER")) {
            acc.comityMembers.push(member);
          }
          if (siteNameSplit.includes("COORDINATOR")) {
            acc.coordinators.push(member);
          }
          return acc;
        },
        { comityMembers: [], coordinators: [] } as ReduceMembers
      );

      setCoordinators(dataReduced.coordinators);
      setComityMembers(dataReduced.comityMembers);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page">
      <CustomBreadcrumbs links={[{ text: title }]} />
      <h1 className="page-title">{title}</h1>

      <div className="page-sections">
        <Section title="Coordinateurs" members={coordinators} />
        <Section title="Membres du comité" members={comityMembers} />
      </div>
    </div>
  );
};

export default Comity;
