import { useEffect } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import infection from "../../assets/icons/categories/infection.svg";
import antiInfectives from "../../assets/icons/categories/anti-infectives.svg";
import microorganisms from "../../assets/icons/categories/microorganisms.svg";
import vaccines from "../../assets/icons/categories/vaccines.svg";

import api from "../../utils/api/axios";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { setRecentSheets } from "../../store/reducers/recentSheets.slice";
import { getMainCategoryColor } from "../../utils/categories";
import { RecentSheet } from "../../types/api/sheet";

const RecentSheetItem = ({ sheet }: { sheet: RecentSheet }) => {
  const getMainCategoryIcon = (id: number) => {
    switch (id) {
      case 1:
        return infection;
      case 2:
        return antiInfectives;
      case 3:
        return microorganisms;
      case 4:
        return vaccines;
      default:
        return undefined;
    }
  };

  const icon = getMainCategoryIcon(sheet.mainCategoryId);
  const color = getMainCategoryColor(sheet.mainCategoryId);

  return (
    <Link className="recent-sheet__item" to={`/fiche/${sheet.id}`}>
      {icon && (
        <img
          className={`recent-sheet__item--icon background-${color}`}
          src={icon}
          alt="icon"
        />
      )}
      <div className="recent-sheet__item--content">
        <p className="recent-sheet__item--title">{sheet.name}</p>
        <p className="recent-sheet__item--last-update">
          Mise à jour le {dayjs(sheet.updatedAt).format("DD/MM/YYYY")}
        </p>
      </div>
    </Link>
  );
};

const RecentSheets = () => {
  const dispatch = useAppDispatch();
  const recentSheets = useAppSelector(
    (state) => state.recentSheets.recentSheets
  );
  const hasNoNetwork = useAppSelector((state) => state.auth.hasNoNetwork);

  const fetchData = async () => {
    try {
      const { data } = await api.get<RecentSheet[]>(`/sheets/recent`);
      dispatch(setRecentSheets(data));
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    if (!hasNoNetwork) fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNoNetwork]);

  return recentSheets.length > 0 ? (
    <div className="recent-sheets content">
      <h2 className="recent-sheets__title">Les fiches mise à jour récemment</h2>
      <div className="recent-sheets__content">
        {recentSheets.map((sheet) => (
          <RecentSheetItem key={sheet.id} sheet={sheet} />
        ))}
      </div>
    </div>
  ) : null;
};

export default RecentSheets;
