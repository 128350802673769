import { useEffect } from "react";
import { Button } from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";

import api from "../../utils/api/axios";
import { useAppSelector } from "../../store/hook";
import { UserSubscription } from "../../types/api/subscription";

import "./SubscriptionRecap.css";
import dayjs from "dayjs";
import { codeSubscriptionTimeUnitLabels } from "../../utils/subscription";
import { notifyApiSuccess } from "../../utils/api/notifyApi";

export async function loader({
  params,
}: any): Promise<{ subscription: UserSubscription | null }> {
  try {
    const { data: subscription } = await api.get<UserSubscription>(
      "/subscription",
      { params }
    );
    return { subscription };
  } catch (_) {
    console.error("Error fetching subscription", _);
    return { subscription: null };
  }
}

interface TableValue {
  label: string;
  value: string | JSX.Element;
}

interface SectionProps {
  title: string;
  headerText?: string;
  tableValues: (TableValue | null)[];
}

const Section = ({ title, headerText, tableValues }: SectionProps) => (
  <div className="subscription-recap-section">
    <h5 className="subscription-recap-section__title">{title}</h5>
    <table className="subscription-recap-section__content">
      <tbody className="subscription-recap-section__content__body">
        {headerText && (
          <tr className="subscription-recap-section__content__body__row first-row header-text">
            <td colSpan={2}>{headerText}</td>
          </tr>
        )}
        {tableValues.map(
          (item, index) =>
            item && (
              <tr
                key={index}
                className={`subscription-recap-section__content__body__row ${
                  index === 0 && !headerText ? "first-row" : ""
                }`}
              >
                <td>{item.label}</td>
                <td>{item.value}</td>
              </tr>
            )
        )}
      </tbody>
    </table>
  </div>
);

const SubscriptionRecap = () => {
  const payload = useLoaderData();
  const subscription = (payload as { subscription: UserSubscription })
    .subscription;

  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);

  const handleSubmit = async (isFree: boolean) => {
    if (isFree) {
      try {
        await api.post(`/subscription/activate/${subscription?.id}`);
        notifyApiSuccess("Votre extension d'abonnement a été activée");
        navigate("/");
      } catch (e) {
        console.error("Error activating subscription", e);
      }
    } else {
      try {
        const { data } = await api.get(`/subscription/pay/${subscription?.id}`);
        window.location.href = data.url;

        // notifyApiSuccess("Votre abonnement a été payé");
        // navigate("/");
      } catch (e) {
        console.error("Error paying subscription", e);
      }
    }
  };

  useEffect(() => {
    if (!subscription) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);

  if (!user || !subscription) return null;

  const {
    fullname,
    email,
    establishment,
    address,
    zipCode,
    city,
    country,
    expiresAt,
    htPrice,
    timeUnit,
    timeUnitValue,
    taxPourcent,
    taxPrice,
    ttcPrice,
  } = subscription;

  const { title, firstname, lastname, establishment: userEstablishment } = user;

  const userFullname = `${
    title === "MR" ? "M." : "Mme."
  } ${firstname} ${lastname}`;
  const isFree = ttcPrice === 0;
  const subscriptionTimeUnitLabel =
    codeSubscriptionTimeUnitLabels.find((v) => v.value === timeUnit)?.[
      timeUnitValue ? "label" : "labelPlural"
    ] ?? "an";
  const timeUnitLabel = `${timeUnitValue} ${subscriptionTimeUnitLabel}`;
  const today = dayjs().format("DD/MM/YYYY");
  const expiresAtDate = dayjs(expiresAt).format("DD/MM/YYYY");
  const headerTextSubscription = `${timeUnitLabel} d'abonnement au site ePOPI du ${today} au ${expiresAtDate}`;

  return (
    <div className="subscription-recap-main">
      <h2 className="subscription-recap-title">
        Recapitulatif{" "}
        {isFree ? "de l'extension d'abonnement" : "avant paiement"}
      </h2>

      <p className="subscription-recap-subtitle">
        Dernier clic avant le paiement de l'abonnement
      </p>

      <div className="subscription-recap-alert__container">
        <p className="subscription-recap-alert">
          En cas d'erreur, merci de noter tout message et/ou faire une capture
          d'écran, afin de pouvoir nous les transmettre si besoin.
        </p>
      </div>

      {isFree ? (
        <p className="subscription-recap-subtitle">
          En cliquant le lien ci-dessous, vous confirmez l'utilisation du code
          promo.
        </p>
      ) : null}

      <Section
        title="Votre compte ePOPI"
        tableValues={[
          { label: "Nom", value: userFullname },
          { label: "Identifiant", value: email },
        ]}
      />

      <Section
        title="Votre commande"
        headerText={headerTextSubscription}
        tableValues={
          isFree
            ? [
                {
                  label: `${timeUnitLabel} d'abonnement à ePOPI`,
                  value: "Extension d'abonnement",
                },
                {
                  label: "Prix final TTC",
                  value: ttcPrice.toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }),
                },
              ]
            : [
                {
                  label: "Coût unitaire hors taxe",
                  value: htPrice.toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }),
                },
                {
                  label: `TVA ${taxPourcent}%`,
                  value: taxPrice.toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }),
                },
                {
                  label: "Prix TTC",
                  value: ttcPrice.toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }),
                },
                {
                  label: "Prix final TTC",
                  value: ttcPrice.toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }),
                },
              ]
        }
      />

      {!isFree && (
        <Section
          title="Information de facturation"
          tableValues={[
            { label: "Nom", value: fullname },
            establishment
              ? {
                  label: "Société / établissement",
                  value: establishment ?? userEstablishment,
                }
              : userEstablishment
              ? {
                  label: "Société / établissement",
                  value: userEstablishment,
                }
              : null,
            {
              label: "Coordonnées",
              value: (
                <p>
                  <span>{address}</span>
                  <br />
                  <span>
                    {zipCode} {city}
                  </span>
                  <br />
                  <span>{country}</span>
                </p>
              ),
            },
          ]}
        />
      )}

      <Button
        sx={{
          width: { xs: "100%", md: "min-content", textWrap: "nowrap" },
        }}
        variant="contained"
        size="large"
        onClick={() => handleSubmit(isFree)}
      >
        {isFree ? "Activer l'extension d'abonnement" : "Payer mon abonnement"}
      </Button>
    </div>
  );
};

export default SubscriptionRecap;
