import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";

const StyledDialog = styled(Dialog)({
  ".MuiDialog-paper": {
    borderRadius: "8px",
    maxWidth: 600,
  },
});

const StyledDialogTitle = styled(DialogTitle)({
  fontSize: 20,
  fontWeight: 600,
  color: "var(--navy)",
  padding: "24px",
});

const StyledDialogContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  gap: 24,
  padding: "0 24px",
});

const StyledDialogActions = styled(DialogActions)({
  padding: "24px",
});

const DualInput = styled(Box)({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "8px 16px",
});

export {
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
  DualInput,
};
