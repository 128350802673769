import { forwardRef, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { useAppSelector } from "../../store/hook";
import { SheetSearch } from "../../types/api/search";

import SearchIcon from "../../assets/icons/search";
import { get, keys } from "idb-keyval";
import {
  GetAllDataCategory,
  GetAllDataSheet,
  GetAllDataVaccins,
} from "../../types/api/allDatas";

interface SearchBarProps {
  bordered?: boolean;
}
const removeAccents = (name: string) =>
  name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const searchInsensitiveToAccentsAndCase = (search: string, name: string) => {
  const normalizedText = removeAccents(name).toLowerCase();
  const normalizedSearchTerm = removeAccents(search).toLowerCase();
  return normalizedText.includes(normalizedSearchTerm);
};

const SearchBar = forwardRef<HTMLInputElement, any>(
  ({ bordered }: SearchBarProps, ref) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isAuth = useAppSelector((state) => state.auth);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<SheetSearch[]>([]);
    // useEffect(() => {
    //   if (isAuth) fetch(inputValue);
    // }, [inputValue, isAuth]);

    const getOptions = async () => {
      const keys_ = await keys();
      const newSheetOptions: SheetSearch[] = await Promise.all(
        keys_.map(async (key) => {
          const value = await get<
            GetAllDataSheet | GetAllDataCategory | GetAllDataVaccins
          >(key);
          const typeString = key.toString().split("-")[0];
          const type =
            typeString === "sheet"
              ? "sheet"
              : typeString === "vaccin"
              ? "vaccin"
              : "category";
          return { id: value.id, name: value.name, type: type };
        })
      );
      setOptions(newSheetOptions);
    };

    useEffect(() => {
      getOptions();
    }, [isAuth]);
    return (
      <div className="search-bar">
        <Autocomplete
          sx={{
            "& .MuiInputBase-root": {
              border: bordered ? "1px solid var(--light-grey)" : "none",
              borderRadius: "8px",
            },
            "& .MuiAutocomplete-popupIndicator": {
              display: "none",
            },
            "&.MuiAutocomplete-hasPopupIcon": {
              "& .MuiOutlinedInput-root": {
                padding: { xs: "4px 8px", md: "16px" },
              },
              "& .MuiInputBase-input": {
                padding: 0,
              },
            },
          }}
          fullWidth
          disableClearable
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          filterOptions={(options) => {
            if (inputValue === "") return [];
            return options.filter((option) =>
              searchInsensitiveToAccentsAndCase(inputValue, option.name)
            );
          }}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          openOnFocus
          noOptionsText="Pas de résultats"
          value={options.find((option) => option.name === inputValue)}
          inputValue={inputValue}
          onChange={(_, newValue) => {
            switch (newValue.type) {
              case "vaccin":
                navigate(`/vaccin/${newValue.id}`);
                break;
              case "sheet":
                navigate(`/fiche/${newValue.id}`);
                break;
              case "category":
                navigate(`/categorie/${newValue.id}`);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              sx={{
                "&:hover": {
                  border: "none",
                },
                "&:focus": {
                  border: "none",
                },
                "& .MuiOutlinedInput-root": {
                  padding: 0,
                  backgroundColor: "white",
                  border: "none",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInputBase-input": {
                  color: "var(--black)",
                  fontSize: { xs: "14px", md: "16px" },
                  border: "none",
                },
              }}
              placeholder="Rechercher une infection, un vaccin..."
              inputRef={ref}
              {...params}
              fullWidth
              InputProps={{
                ...params?.InputProps,
                startAdornment: !isSmallScreen ? (
                  <InputAdornment position="start" sx={{ height: "24px" }}>
                    <SearchIcon color="black" />
                  </InputAdornment>
                ) : undefined,
                endAdornment: isSmallScreen ? (
                  <InputAdornment
                    position="end"
                    sx={{ height: "40px", maxHeight: "40px" }}
                  >
                    <button
                      onClick={() => fetch(inputValue)}
                      className="search-bar__input-end"
                    >
                      <SearchIcon color="white" />
                    </button>
                  </InputAdornment>
                ) : undefined,
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={`${option.type}-${option.name}-${option.id}`}>
              <Link
                className="search-bar__option"
                to={`/${option.type === "sheet" ? "fiche" : "categorie"}/${
                  option.id
                }`}
              >
                <p className="search-bar__option--name">{option.name}</p>
              </Link>
            </li>
          )}
        />

        {!isSmallScreen && (
          <Button
            sx={{
              height: "56px",
              borderRadius: "8px",
              fontSize: 18,
            }}
            variant="contained"
            size="large"
            onClick={() => fetch(inputValue)}
          >
            Rechercher
          </Button>
        )}
      </div>
    );
  }
);

export default SearchBar;
