interface ChevronProps {
  onClick: (e: React.MouseEvent) => void;
  variant: "up" | "down";
}

const Chevron = ({ onClick, variant }: ChevronProps) => (
  <svg
    className={["chevron", variant].join(" ")}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <g id="Chevron Down">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66785 6.94732C9.85091 6.76426 10.1477 6.76426 10.3307 6.94732L15.1098 11.7264C15.2928 11.9094 15.2928 12.2062 15.1098 12.3893L14.8888 12.6103C14.7057 12.7933 14.409 12.7933 14.2259 12.6103L9.99929 8.38364L5.77267 12.6103C5.5896 12.7933 5.29285 12.7933 5.10979 12.6103L4.88879 12.3893C4.70573 12.2062 4.70573 11.9094 4.88879 11.7264L9.66785 6.94732Z"
        fill="#2D3B5B"
      />
    </g>
  </svg>
);

export default Chevron;
