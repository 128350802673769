import api from "./api/axios";

const getOldSheetRedirection = async (
  legacySheetId: string,
  legacyCatId: string
) => {
  try {
    const { data: sheetId } = await api.get<number[]>(
      `/sheet/legacy-redirection/${legacySheetId}/${legacyCatId}`
    );
    if (!sheetId) return;
    return sheetId;
  } catch (_) {}
};

const getOldCategoryRedirection = async (legacyCatId: string) => {
  try {
    const { data: categoryId } = await api.get<number[]>(
      `/category/legacy-redirection/${legacyCatId}`
    );
    if (!categoryId) return;
    return categoryId;
  } catch (_) {}
};

export { getOldSheetRedirection, getOldCategoryRedirection };
