import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const StyledTextField = styled(TextField)({
  width: "210px",
  "& .MuiOutlinedInput-root": {
    padding: "8px",
    borderRadius: 4,
  },
  "& .MuiInputBase-input": {
    padding: 0,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--grey-800)",
  },
});
