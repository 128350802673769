import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { GetAllDataCategory } from "../../types/api/allDatas";

interface CategoriesState {
  [key: number]: GetAllDataCategory | undefined;
}

const initialState: CategoriesState = {};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setAllCategoriesData: (
      state,
      action: PayloadAction<GetAllDataCategory[]>
    ) => {
      action.payload.forEach((category) => {
        state[category.id] = category;
      });
    },
    deleteAllCategories: (state) => {
      state = {};
    },
  },
});

export const { setAllCategoriesData, deleteAllCategories } =
  categoriesSlice.actions;

export default categoriesSlice.reducer;
