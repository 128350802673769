import { useCallback, useEffect, useState } from "react";
import { InputAdornment, useMediaQuery } from "@mui/material";

import api from "../../utils/api/axios";
import alphabet from "../../utils/alphabet";
import { getChidrenLetterAndIndex } from "../../utils/abbreviations";
import { Abbreviation } from "../../types/api/abbreviations";
import { LetterIndex } from "../../types/letter";

import Loader, { StyledCircularProgress } from "../../components/Loader";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";
import { StyledTextField } from "../../components/StyledTextField";
import NoData from "../../components/NoData";

import SearchIcon from "../../assets/icons/search";

import "./Abbreviations.css";

const Abbreviations = () => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [visibleLetter, setVisibleLetter] = useState<string>("A");
  const [abbreviations, setAbbreviations] = useState<Abbreviation[]>([]);
  const [filteredAbbreviations, setFilteredAbbreviations] = useState<
    Abbreviation[]
  >([]);
  const [childrenLetterAndIndex, setChildrenLetterAndIndex] =
    useState<LetterIndex>();
  const firstLettersRefs: { [key: number]: HTMLElement | null } = {};
  const lettersRefs: { [key: string]: HTMLElement | null } = {};

  const goToLetter = (letter: string) => {
    const firstLetterIndex = childrenLetterAndIndex?.get(letter);
    if (firstLetterIndex === undefined) return;

    const abbreviation = firstLettersRefs[firstLetterIndex];
    if (!abbreviation) return;

    const top = abbreviation.offsetTop;
    const topOffset = top - 30;
    window.scrollTo({ top: topOffset, behavior: "smooth" });
  };

  const onScroll = () => {
    // scroll to first letter in list
    const entriesFirstLetters = Object.entries(firstLettersRefs);
    const firstVisible = entriesFirstLetters.find(
      ([, ref]) =>
        ref && ref.getBoundingClientRect().top >= (isSmallScreen ? 60 : 110)
    );
    const firstVisibleLetter = firstVisible?.[1]?.ariaLabel || "";
    setVisibleLetter(firstVisibleLetter);

    // scroll to letter in letter filter on small screen
    const alphabetDiv = document.getElementById("abbreviations-alphabet");
    const letterRef = lettersRefs[firstVisibleLetter];
    if (!alphabetDiv || !letterRef) return;

    const letterOffset = letterRef.getBoundingClientRect();
    const alphabetOffset = alphabetDiv.getBoundingClientRect();
    if (letterOffset.right > alphabetOffset.right) {
      alphabetDiv.scrollLeft += letterOffset.right - alphabetOffset.right + 30;
    }
    if (letterOffset.left < alphabetOffset.left) {
      alphabetDiv.scrollLeft -= alphabetOffset.left - letterOffset.left + 30;
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await api.get<Abbreviation[]>(`/abbreviations/pwa`);

      setAbbreviations(data);
      setFilteredAbbreviations(data);
      setChildrenLetterAndIndex(getChidrenLetterAndIndex(data));
    } catch (_) {}
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const copyAbbreviations = JSON.parse(
      JSON.stringify(abbreviations)
    ) as Abbreviation[];
    const filteredAbbreviations = copyAbbreviations
      .filter((item) =>
        search
          ? item.abbreviation.toLowerCase().includes(search.toLowerCase())
          : true
      )
      .sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
    setChildrenLetterAndIndex(getChidrenLetterAndIndex(filteredAbbreviations));
    setFilteredAbbreviations(filteredAbbreviations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filteredAbbreviations]);

  useEffect(() => {
    onScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAbbreviations]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLettersRefs]);

  return (
    <div className="abbreviations-container">
      <CustomBreadcrumbs links={[{ text: "Abréviations" }]} />

      <div>
        <div className="abbreviations-title__container">
          <h1 className={["abbreviations-title", "navy"].join(" ")}>
            {isLoading ? <StyledCircularProgress size={24} /> : "Abréviations"}
          </h1>

          <StyledTextField
            placeholder="Recherche rapide"
            sx={{ display: { xs: "none", sm: "block" } }}
            value={search}
            disabled={abbreviations.length === 0}
            onChange={handleSearchChange}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    color={abbreviations.length === 0 ? "grey-300" : "grey-800"}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div
          id="abbreviations-alphabet"
          className="abbreviations-alphabet-container"
        >
          {alphabet.map((l) => (
            <p
              key={l}
              ref={(ref) => (lettersRefs[l] = ref)}
              className={[
                "abbreviations-alphabet-item",
                !childrenLetterAndIndex?.has(l)
                  ? "disabled"
                  : visibleLetter === l
                  ? "navy"
                  : "",
              ].join(" ")}
              onClick={() => goToLetter(l)}
            >
              {l}
            </p>
          ))}
        </div>

        <div className="abbreviations-result">
          {isLoading ? (
            <Loader />
          ) : abbreviations.length === 0 ? (
            <NoData text="Aucune abréviation" />
          ) : (
            filteredAbbreviations.map((item, index) => (
              <div
                key={`${item.id}-${item.name}`}
                ref={(ref) => (firstLettersRefs[index] = ref)}
                aria-label={item.name[0].toUpperCase()}
                className="abbreviations-result-item"
              >
                <p className="abbreviations-result-item__abreviation">
                  {item.abbreviation}
                </p>
                <p className="abbreviations-result-item__name">{item.name}</p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Abbreviations;
