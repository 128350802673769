import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { GetAllDataSheet } from "../../types/api/allDatas";

interface SheetsState {
  [key: number]: GetAllDataSheet | undefined;
}

interface SheetsPayloadAction {
  index: number;
  sheet: GetAllDataSheet;
}

const initialState: SheetsState = {};

export const sheetsSlice = createSlice({
  name: "sheets",
  initialState,
  reducers: {
    setSheet: (state, action: PayloadAction<SheetsPayloadAction>) => {
      state[action.payload.sheet.id] = action.payload.sheet;
    },
    setAllSheetsData: (state, action: PayloadAction<GetAllDataSheet[]>) => {
      const sheets: SheetsState = {};
      action.payload.forEach((sheet) => {
        state[sheet.id] = sheet;
      });
      state = sheets;
    },
    deleteAllSheets: (state) => {
      state = {};
    },
  },
});

export const { setSheet, setAllSheetsData, deleteAllSheets } =
  sheetsSlice.actions;

export default sheetsSlice.reducer;
