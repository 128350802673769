import { useEffect, useState } from "react";
import { Autocomplete, MenuItem, TextField } from "@mui/material";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";

import { useAppDispatch, useAppSelector } from "../../store/hook";
import { setUser } from "../../store/reducers/auth.slice";
import { userCountryLabels } from "../../utils/user";
import { UserCountry } from "../../types/user";

import SettingsSection, { Cta } from "./Section";
import api from "../../utils/api/axios";
import { Invoice } from "../../types/api/invoice";
import dayjs from "dayjs";
import { pdf } from "@react-pdf/renderer";
import InvoicePdf from "./InvoicePdf";
import { notifyApiSuccess } from "../../utils/api/notifyApi";
import DownloadIcon from "@mui/icons-material/Download";

const schema = z.object({
  invoiceEstablishment: z.string().optional(),
  invoiceService: z.string().optional(),
  invoiceAddress: z.string().optional(),
  invoiceZipCode: z.string().optional(),
  invoiceCity: z.string().optional(),
  invoiceCountry: z.nativeEnum(UserCountry).or(z.string().refine((v) => !v)),
});

type FormData = z.infer<typeof schema>;

const Invoices = () => {
  const auth = useAppSelector((state) => state.auth);
  const { user } = auth;
  const dispatch = useAppDispatch();

  const [edit, setEdit] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const { Field, handleSubmit } = useForm<FormData, typeof zodValidator>({
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      try {
        if (!user) return;
        await api.put(`/profile/invoicing/infos/${user.id}`, value);
        dispatch(
          setUser({
            ...user,
            ...value,
            invoiceCountry: value.invoiceCountry as UserCountry | undefined,
          })
        );
        setEdit(false);
      } catch (_) {}
    },
  });

  const getMyInvoices = async () => {
    try {
      const { data } = await api.get<Invoice[]>("/invoices/me");
      if (data) {
        setInvoices(data);
      }
    } catch (_) {}
  };

  useEffect(() => {
    getMyInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
  const ctaEditInvoicingInfos: Cta[] = [
    edit
      ? {
          children: "Enregister mes changements",
          onClick: () => handleSubmit(),
          variant: "contained",
          type: "submit",
        }
      : {
          children: "Modifier mes infos",
          onClick: () => setEdit(!edit),
          variant: "contained",
          type: "button",
        },
  ];

  if (!user) return null;

  const {
    invoiceEstablishment,
    invoiceService,
    invoiceAddress,
    invoiceZipCode,
    invoiceCity,
    invoiceCountry,
  } = user;

  const viewPDF = (invoice: Invoice) => {
    pdf(<InvoicePdf invoice={invoice} />)
      .toBlob()
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Facture n°${invoice.reference}.pdf`;
        a.click();
        URL.revokeObjectURL(url);
        notifyApiSuccess("Facture téléchargée");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <SettingsSection title="Ma facturation" cta={ctaEditInvoicingInfos}>
        <div className="dual-input-container">
          <Field
            name="invoiceEstablishment"
            defaultValue={invoiceEstablishment ?? ""}
            validators={{ onChange: schema.shape.invoiceEstablishment }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Société / établissement</p>
                <TextField
                  size="small"
                  id="invoiceEstablishment"
                  type="text"
                  disabled={!edit}
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              </div>
            )}
          />
          <Field
            name="invoiceService"
            defaultValue={invoiceService ?? ""}
            validators={{ onChange: schema.shape.invoiceService }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Service</p>
                <TextField
                  size="small"
                  id="invoiceService"
                  type="text"
                  disabled={!edit}
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              </div>
            )}
          />
        </div>

        <div className="dual-input-container">
          <Field
            name="invoiceAddress"
            defaultValue={invoiceAddress ?? ""}
            validators={{ onChange: schema.shape.invoiceAddress }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Adresse</p>
                <TextField
                  size="small"
                  id="invoiceAddress"
                  type="text"
                  disabled={!edit}
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              </div>
            )}
          />
          <Field
            name="invoiceZipCode"
            defaultValue={invoiceZipCode ?? ""}
            validators={{ onChange: schema.shape.invoiceZipCode }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Code postal</p>
                <TextField
                  size="small"
                  id="invoiceZipCode"
                  type="text"
                  disabled={!edit}
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              </div>
            )}
          />
        </div>

        <div className="dual-input-container">
          <Field
            name="invoiceCity"
            defaultValue={invoiceCity ?? ""}
            validators={{ onChange: schema.shape.invoiceCity }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Ville</p>
                <TextField
                  size="small"
                  id="invoiceCity"
                  type="text"
                  disabled={!edit}
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              </div>
            )}
          />
          <div className="input-container">
            <Field
              name="invoiceCountry"
              defaultValue={invoiceCountry ?? ""}
              validators={{ onChange: schema.shape.invoiceCountry }}
              children={(field) => (
                <>
                  <p className="input-label">Pays</p>
                  <Autocomplete
                    id="invoiceCountry"
                    disabled={!edit}
                    value={userCountryLabels.find(
                      (countryLabel) => countryLabel.value === field.state.value
                    )}
                    options={userCountryLabels}
                    getOptionLabel={(option) => option.value}
                    getOptionKey={(option) => option.value}
                    onChange={(_, value) =>
                      field.handleChange(value?.value ?? "")
                    }
                    renderOption={(props, country) => (
                      <MenuItem
                        {...props}
                        key={country.value}
                        value={country.value}
                      >
                        {country.label}
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={!!field.state.meta.errors.length}
                      />
                    )}
                  />
                </>
              )}
            />
          </div>
        </div>
      </SettingsSection>
      <SettingsSection title="Mes factures" noGoBack>
        <div className="invoices-container">
          {invoices.map((invoice) => (
            <div key={invoice.id} className="invoice-container">
              <div className="invoice-title-container">
                <p className="invoice-title">{invoice.reference}</p>
                <DownloadIcon
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => viewPDF(invoice)}
                />
              </div>

              <p>NOM : {invoice.fullname}</p>
              <p>PRIX : {invoice.price}</p>
              <p>DATE : {dayjs(invoice.createdAt).format("DD/MM/YYYY")}</p>
            </div>
          ))}
        </div>
      </SettingsSection>
    </>
  );
};

export default Invoices;
