import { Box } from "@mui/material";

interface NoDataProps {
  text?: string;
}

const NoData = ({ text }: NoDataProps) => (
  <Box className="result-infos__container">
    <h5 className="no-data__text">{text ?? "Aucune donnée"}</h5>
  </Box>
);

export default NoData;
