import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { User } from "../../types/api/user";
import {
  UserRegistration,
  UserRegistrationBase,
  UserRegistrationStep1,
  UserRegistrationStep2,
} from "../../types/user";

interface UserState {
  isAuth: boolean;
  hasNoNetwork: boolean;
  user: User | null;
  userRegistration: Partial<UserRegistration> | null;
}

const initialState: UserState = {
  isAuth: false,
  hasNoNetwork: false,
  user: null,
  userRegistration: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<User>) => {
      state.isAuth = true;
      state.user = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setDisconnect: (state) => {
      state.isAuth = false;
      state.user = null;
    },
    setUserRegistration: (
      state,
      action: PayloadAction<UserRegistrationBase>
    ) => {
      state.userRegistration = { ...state.userRegistration, ...action.payload };
    },
    setUserRegistrationStep1: (
      state,
      action: PayloadAction<UserRegistrationStep1>
    ) => {
      state.userRegistration = { ...state.userRegistration, ...action.payload };
    },
    setUserRegistrationStep2: (
      state,
      action: PayloadAction<Partial<UserRegistrationStep2>>
    ) => {
      state.userRegistration = { ...state.userRegistration, ...action.payload };
    },
    deleteUserRegistration: (state) => {
      state.userRegistration = null;
    },
    setHasNoNetwork: (state, action: PayloadAction<boolean>) => {
      state.hasNoNetwork = action.payload;
    },
  },
});

export const {
  setAuthUser,
  setUser,
  setDisconnect,
  setUserRegistration,
  setUserRegistrationStep1,
  setUserRegistrationStep2,
  deleteUserRegistration,
  setHasNoNetwork,
} = authSlice.actions;

export default authSlice.reducer;
