import { useState } from "react";
import {
  AppBar,
  Button,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { useAppSelector } from "../../store/hook";
import {
  getHeaderMenuSections,
  getHeaderSubMenuSections,
} from "../../utils/menus";
import { UserRole } from "../../types/api/user";

import epopiLogo from "../../assets/logos/logo.svg";
import menuIcon from "../../assets/icons/menu.svg";
import crossIcon from "../../assets/icons/cross.svg";
import pencilIcon from "../../assets/icons/pencil.svg";
import SearchIcon from "../../assets/icons/search";
import UserIcon from "../../assets/icons/user";

import SearchBar from "../home/SearchBar";
import ProfileMenu from "./ProfileMenu";
import HeaderMenu, { HeaderMenuItems, HeaderMenuSection } from "./HeaderMenu";
import Chevron from "../Chevron";

const HeaderButton = styled(Button)(({ theme, variant }) => ({
  minWidth: 64,
  padding: "14px 16px",
  borderRadius: 8,
  fontSize: 16,
  fontWeight: 600,
  color: variant === "contained" ? "var(--white)" : "var(--black)",
  "&:hover": {
    backgroundColor: variant === "contained" ? "var(--navy)" : "transparent",
    boxShadow: "none",
  },
  "& img": {
    width: 24,
    height: 24,
  },
  [theme.breakpoints.down("md")]: {
    padding: 0,
    minWidth: 0,
  },
}));

const DrawerHeaderSpacer = styled("div")(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Header = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isTinyScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const auth = useAppSelector((state) => state.auth);
  const { isAuth, user } = auth;
  const isIpAuth = isAuth && user?.role === UserRole.ESTABLISHMENT;

  const [open, setOpen] = useState(false);
  const [anchorAccount, setAnchorAccount] = useState<null | HTMLElement>(null);
  const openAccountMenu = Boolean(anchorAccount);
  const [anchorHeaderMenu, setAnchorHeaderMenu] = useState<null | HTMLElement>(
    null
  );

  const openHeaderMenu = Boolean(anchorHeaderMenu);
  const [headerMenuSections, setHeaderMenuSections] = useState<
    HeaderMenuSection[]
  >([]);
  const [subMenuOpen, setSubMenuOpen] = useState<null | "about" | "ressources">(
    null
  );

  const handleAccountMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (!isIpAuth) setAnchorAccount(event.currentTarget);
  };

  const handleAccountMenuClose = (closeTab?: boolean) => {
    setAnchorAccount(null);
    if (closeTab) setOpen(false);
  };

  const handleHeaderMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    section: "about" | "ressources"
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorHeaderMenu(event.currentTarget);
    setHeaderMenuSections(getHeaderMenuSections(section));
  };

  const handleHeaderMenuClose = () => {
    setAnchorHeaderMenu(null);
  };

  const setOpenSubMenu = (item: "about" | "ressources") => {
    setSubMenuOpen((prev) => (prev === item ? null : item));
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const navigateTo = (url: string) => {
    navigate(url);
    setOpen(false);
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow:
            open && isTinyScreen ? "none" : "0px 1px 4px rgba(0, 0, 0, 0.1)",
          backgroundColor: "white",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "24px",
            padding: {
              sx: "16px",
              sm: "16px",
              md: "16px",
              lg: "16px 32px",
            },
          }}
        >
          <div className="header-part">
            <IconButton
              onClick={handleDrawerToggle}
              sx={{ display: { xs: "flex", sm: "none" }, padding: 0 }}
            >
              {open ? (
                <img src={crossIcon} alt="fermer" />
              ) : (
                <img src={menuIcon} alt="menu" />
              )}
            </IconButton>
            <Link
              to="/"
              className="header-logo--container"
              onClick={() => setOpen(false)}
            >
              <img className="header-logo" src={epopiLogo} alt="epopi logo" />
            </Link>
          </div>

          <div className="header-part">
            <HeaderButton
              disableRipple
              sx={{ display: { xs: "none", sm: "block" }, padding: "4px" }}
              onClick={(event) => handleHeaderMenuClick(event, "about")}
            >
              <p>À propos</p>
            </HeaderButton>
            <HeaderButton
              disableRipple
              sx={{ display: { xs: "none", sm: "block" }, padding: "4px" }}
              onClick={(event) => handleHeaderMenuClick(event, "ressources")}
            >
              <p>Ressources</p>
            </HeaderButton>
            <HeaderButton
              disableRipple
              sx={{ display: { xs: "none", sm: "block" }, padding: "4px" }}
              onClick={() => navigate("/contact")}
            >
              <p>Nous contacter</p>
            </HeaderButton>
            {isAuth && (
              <HeaderButton
                disableRipple
                sx={{ padding: "4px" }}
                onClick={() => navigate("/?type=search")}
              >
                <SearchIcon />
                {!isSmallScreen ? <p>Rechercher</p> : null}
              </HeaderButton>
            )}

            {isAuth || isSmallScreen ? (
              <Button
                disableRipple
                onClick={handleAccountMenuClick}
                variant={isSmallScreen ? "text" : "contained"}
                sx={{
                  padding: { xs: "7.5px", md: "8px 10px" },
                  borderRadius: "8px",
                  minWidth: "auto",
                  fontSize: 16,
                  fontWeight: 500,
                  "&:hover": {
                    backgroundColor: { xs: "transparent", md: "var(--navy)" },
                  },
                }}
              >
                <UserIcon color={isSmallScreen ? "navy" : "white"} />
                {!isSmallScreen && user
                  ? `${user.firstname} ${user.lastname[0]}.`
                  : null}
              </Button>
            ) : (
              <div className="header-connect-buttons">
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    padding: "8px 10px",
                    borderRadius: "8px",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                  onClick={() => navigate("/connexion/s-inscrire")}
                >
                  {isLargeScreen ? (
                    <img src={pencilIcon} alt="s'inscrire" />
                  ) : null}
                  <p>S'abonner</p>
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  sx={{
                    padding: "8px 10px",
                    borderRadius: "8px",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                  onClick={() => navigate("connexion/se-connecter")}
                >
                  {isLargeScreen ? <UserIcon color="white" /> : null}
                  <p>Se connecter</p>
                </Button>
              </div>
            )}

            <ProfileMenu
              anchorEl={anchorAccount}
              open={openAccountMenu}
              isAuth={isAuth}
              handleClose={handleAccountMenuClose}
            />

            <HeaderMenu
              sections={headerMenuSections}
              anchorEl={anchorHeaderMenu}
              open={openHeaderMenu}
              handleClose={handleHeaderMenuClose}
            />
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          display: { xs: "block", sm: "none" },
          width: "100%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "100%",
            boxSizing: "border-box",
          },
        }}
        variant="temporary"
        anchor="left"
        open={open}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <DrawerHeaderSpacer />

        {isAuth && (
          <div className="side-bar--search">
            <SearchBar bordered={true} />
          </div>
        )}

        <List>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ padding: "16px" }}
              onClick={() => setOpenSubMenu("about")}
            >
              <p className="side-bar--text">À propos</p>
              <Chevron
                onClick={() => {}}
                variant={subMenuOpen === "about" ? "down" : "up"}
              />
            </ListItemButton>
          </ListItem>
          <Collapse in={subMenuOpen === "about"} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ paddingLeft: "24px" }}>
              {getHeaderSubMenuSections("about").map((subItem) => (
                <HeaderMenuItems
                  key={subItem.label}
                  {...subItem}
                  onClick={() => setOpen(false)}
                />
              ))}
            </List>
          </Collapse>
          <Divider sx={{ margin: "0 16px" }} />
          <ListItem disablePadding>
            <ListItemButton
              sx={{ padding: "16px" }}
              onClick={() => setOpenSubMenu("ressources")}
            >
              <p className="side-bar--text">Ressources</p>
              <Chevron
                onClick={() => {}}
                variant={subMenuOpen === "ressources" ? "down" : "up"}
              />
            </ListItemButton>
          </ListItem>
          <Collapse
            in={subMenuOpen === "ressources"}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding sx={{ paddingLeft: "24px" }}>
              {getHeaderSubMenuSections("ressources").map((subItem) => (
                <HeaderMenuItems
                  key={subItem.label}
                  {...subItem}
                  onClick={() => setOpen(false)}
                />
              ))}
            </List>
          </Collapse>
          <Divider sx={{ margin: "0 16px" }} />
          <ListItem disablePadding>
            <ListItemButton
              sx={{ padding: "16px" }}
              onClick={() => navigateTo("/contact")}
            >
              <p className="side-bar--text">Nous contacter</p>
            </ListItemButton>
          </ListItem>
        </List>

        {!isAuth && (
          <div className="header-part">
            <Button
              variant="outlined"
              size="large"
              onClick={() => navigateTo("/connexion/inscription")}
            >
              <img src={pencilIcon} alt="s'inscrire" />
              <p>S'inscrire</p>
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => navigateTo("connexion/se-connecter")}
            >
              <UserIcon color="white" />
              <p>Se connecter</p>
            </Button>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default Header;
