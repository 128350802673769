import { ComityMember } from "../../types/comity";
import Members from "./Members";

interface SectionProps {
  title?: string;
  members?: ComityMember[];
}

const Section = ({ title, members }: SectionProps) =>
  members ? (
    <div className="section">
      {title && <h2 className="section-title">{title}</h2>}
      <div className="section-members">
        {members.map((member) => (
          <Members key={member.id} {...member} />
        ))}
      </div>
    </div>
  ) : null;

export default Section;
