import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import dayjs from "dayjs";

import { Invoice } from "../../types/api/invoice";

interface InvoicePdfProps {
  invoice: Invoice;
}

const InvoicePdf = ({ invoice }: InvoicePdfProps) => {
  const {
    reference,
    fullname,
    email,
    establishment,
    service,
    address,
    zipCode,
    city,
    country,
    price,
    htPrice,
    taxPrice,
    code,
    expiresAt,
    createdAt,
  } = invoice;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image
            fixed
            style={styles.logo}
            src={require("../../assets/images/alineaplus.png")}
          />

          <View style={styles.infosContainer}>
            <Text style={styles.infosText}>{fullname}</Text>
            {establishment && (
              <Text style={styles.infosTextBold}>{establishment}</Text>
            )}
            {service && <Text style={styles.infosTextItalic}>{service}</Text>}
            <Text style={styles.infosTextAdress}>{address}</Text>
            <Text style={styles.infosTextAdress}>{city}</Text>
            <Text style={styles.infosTextAdress}>{zipCode}</Text>
            <Text style={styles.infosTextAdress}>{country}</Text>
          </View>
        </View>

        <Text style={styles.title}>Facture n°{reference}</Text>

        <Text style={[styles.infosText, { marginBottom: 120 }]}>
          Paris, le {dayjs(createdAt).format("DD/MM/YYYY")}
        </Text>

        <View style={styles.table}>
          <View style={styles.column}>
            <View
              style={[styles.cell, styles.cellHeader, styles.cellSubscription]}
            >
              <Text style={styles.bold}>ABONNEMENT</Text>
            </View>
            <View style={[styles.cell, styles.cellHeader, styles.cellDate]}>
              <Text style={styles.bold}>DATE DEBUT</Text>
            </View>
            <View style={[styles.cell, styles.cellHeader, styles.cellDate]}>
              <Text style={styles.bold}>DATE FIN</Text>
            </View>
            <View style={[styles.cell, styles.cellHeader, styles.cellNumber]}>
              <Text style={styles.bold}>NB</Text>
            </View>
            <View style={[styles.cell, styles.cellHeader, styles.cellPrice]}>
              <Text style={styles.bold}>PU HT</Text>
            </View>
            <View style={[styles.cell, styles.cellHeader, styles.cellPrice]}>
              <Text style={styles.bold}>TOTAL HT</Text>
            </View>
          </View>

          <View style={styles.column}>
            <View style={[styles.cell, styles.cellSubscription]}>
              <Text>
                ePOPI pour <Text style={styles.bold}>{fullname}</Text>
              </Text>
              {code && <Text>Code avantage : {code}</Text>}
              <Text>
                <Text style={styles.italic}>Identifiant</Text> : {email}
              </Text>
            </View>
            <View style={[styles.cell, styles.cellDate]}>
              <Text>{dayjs(createdAt).format("DD/MM/YYYY")}</Text>
            </View>
            <View style={[styles.cell, styles.cellDate]}>
              <Text>{dayjs(expiresAt).format("DD/MM/YYYY")}</Text>
            </View>
            <View style={[styles.cell, styles.cellNumber]}>
              <Text>1</Text>
            </View>
            <View style={[styles.cell, styles.cellPrice]}>
              <Text>
                {htPrice.toLocaleString("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                })}
              </Text>
            </View>
            <View style={[styles.cell, styles.cellPrice]}>
              <Text>
                {htPrice.toLocaleString("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                })}
              </Text>
            </View>
          </View>

          <View style={styles.column}>
            <View
              style={[styles.cellSubscription, { borderLeft: "none" }]}
            ></View>
            <View style={styles.cellDate}></View>
            <View style={styles.cellDate}></View>
            <View
              style={[styles.cellNumber, { borderRight: "1px solid black" }]}
            ></View>
            <View style={[styles.cell, styles.cellPrice]}>
              <Text>TVA 20.00%</Text>
            </View>
            <View style={[styles.cell, styles.cellPrice]}>
              <Text>
                {taxPrice.toLocaleString("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                })}
              </Text>
            </View>
          </View>

          <View style={styles.column}>
            <View
              style={[styles.cellSubscription, { borderLeft: "none" }]}
            ></View>
            <View style={styles.cellDate}></View>
            <View style={styles.cellDate}></View>
            <View
              style={[styles.cellNumber, { borderRight: "1px solid black" }]}
            ></View>
            <View style={[styles.cell, styles.cellPrice]}>
              <Text style={styles.bold}>Total TTC</Text>
            </View>
            <View style={[styles.cell, styles.cellPrice]}>
              <Text>
                {price.toLocaleString("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                })}
              </Text>
            </View>
          </View>
        </View>

        <Text style={styles.confirmationText}>
          Facture attestant l'abonnement de {fullname} à ePOPI
        </Text>

        <View style={styles.footer}>
          <Text style={styles.footerText}>
            <Text style={styles.bold}>Alinéa Plus</Text> - SAS au capital de 10
            000 €
          </Text>
          <Text style={styles.footerText}>
            8, rue Froidevaux 75014 PARIS – Tél. : 01 46 67 62 53 – Fax : 01 41
            38 12 02
          </Text>
          <Text style={styles.footerText}>
            RCS Paris 753 930 445 – SIRET 753 930 445 00048 – TVA
            intracommunautaire FR 55 753 930 445
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: 16,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 8,
    width: "100%",
    marginBottom: 50,
  },
  logo: {
    width: 180,
    height: 60,
    objectFit: "contain",
  },
  infosContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: 2,
  },
  infosText: {
    fontSize: 11,
    textAlign: "right",
  },
  infosTextAdress: {
    fontSize: 11,
    textAlign: "right",
    textTransform: "uppercase",
  },
  infosTextBold: {
    fontSize: 11,
    fontFamily: "Helvetica-Bold",
    textAlign: "right",
  },
  infosTextItalic: {
    fontFamily: "Helvetica-Oblique",
    fontSize: 11,
    textAlign: "right",
  },
  title: {
    fontSize: 18,
    fontFamily: "Helvetica-Bold",
    marginBottom: 10,
    textAlign: "center",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    fontSize: 11,
  },
  column: {
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 2,
    padding: "3px 4px",
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
  },
  cellHeader: {
    borderTop: "1px solid black",
    backgroundColor: "#eaeaea",
  },
  cellSubscription: {
    width: "50%",
    borderLeft: "1px solid black",
  },
  cellDate: {
    width: 130,
    alignItems: "center",
  },
  cellNumber: {
    alignItems: "center",
    padding: 0,
    width: 40,
  },
  cellPrice: {
    width: 120,
    alignItems: "flex-end",
  },
  confirmationText: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  italic: {
    fontStyle: "Helvetica-Oblique",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    bottom: 10,
    width: "100%",
  },
  footerText: {
    fontSize: 10,
    color: "grey",
  },
});

export default InvoicePdf;
