import { Link } from "react-router-dom";

import { getMainCategoryColor } from "../../utils/categories";

import ArrowRightTailed from "../../assets/icons/arrows/right-tailed";
import infection from "../../assets/icons/categories/infection.svg";
import antiInfectives from "../../assets/icons/categories/anti-infectives.svg";
import microorganisms from "../../assets/icons/categories/microorganisms.svg";
import vaccines from "../../assets/icons/categories/vaccines.svg";
import spilf from "../../assets/images/partners/spilf.png";

interface CategoryCardProps {
  id: number | string;
  name: string;
  description?: string;
  icon?: string;
  size?: "small" | "medium";
  link?: string;
}

const CategoryCard = ({
  id,
  name,
  description,
  icon,
  size = "medium",
  link,
}: CategoryCardProps) => {
  const color = getMainCategoryColor(typeof id === "number" ? id : 0, true);

  return (
    <Link
      className={`categories-card background-${color} ${size}`}
      to={link ?? `/categorie/${id}`}
      target={typeof id === "string" ? "_blank" : undefined}
    >
      {icon && <img className="categories-card__icon" src={icon} alt="icon" />}
      <div className={`categories-card__content ${size}`}>
        <div className="categories-card__content--title">
          {icon && (
            <img
              className="categories-card__icon-small"
              src={icon}
              alt="icon"
            />
          )}
          <h4 className={`categories-card__title ${size}`}>{name}</h4>
        </div>

        {description && (
          <p className="categories-card__description">{description}</p>
        )}
      </div>
      <div className="categories-card__arrow-icon">
        <ArrowRightTailed color={color === "white" ? "navy" : "white"} />
      </div>
    </Link>
  );
};

interface CategoriesProps {
  isAuth: boolean;
}

const HomeCategories = ({ isAuth }: CategoriesProps) => {
  return (
    <div className={`categories content ${!isAuth ? "not-auth" : ""}`}>
      {!isAuth && (
        <h2 className="categories__title">
          L'expertise en infectiologie à portée de clic.
        </h2>
      )}

      <div className="categories__content">
        <CategoryCard
          id={1}
          name="Infection"
          description="260 maladies infectieuses traitées : de l’épidémiologie à la prescription"
          icon={infection}
        />
        <CategoryCard
          id={3}
          name="Anti-infectieux"
          description="Aide à la prescription pour favoriser le bon usage des anti-infectieux"
          icon={antiInfectives}
        />
        <CategoryCard
          id={2}
          name="Microorganismes"
          description="Près de 250 agents infectieux : bactéries, virus, parasites, champignons"
          icon={microorganisms}
        />
        <CategoryCard
          id={4}
          name="Vaccins"
          description="Outil d’aide à la décision vaccinale de MesVaccins.net et fiches vaccins"
          icon={vaccines}
        />
        <div className="categories__content--small">
          <CategoryCard
            id={0}
            name="Recommandations de la SPILF"
            icon={spilf}
            size="small"
            link="https://www.infectiologie.com/fr/recommandations.html"
          />
          <CategoryCard
            id={5}
            name="Antibioprophylaxie en chirurgie"
            size="small"
          />
        </div>

        <div className="categories__content--small">
          <CategoryCard
            id={6}
            name="Accidents d'exposition au sang et aux liquides biologiques"
            size="small"
          />
          <CategoryCard
            id={4}
            name="MentorPro"
            description="Utilisez notre outil de posologie MentorPro"
            link={"/mentorpro/"}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeCategories;
