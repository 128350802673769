import { Box, CircularProgress, styled } from "@mui/material";

export const StyledCircularProgress = styled(CircularProgress)({
  color: "var(--navy)",
});

interface LoaderProps {
  size?: number;
}

const Loader = ({ size }: LoaderProps) => (
  <Box className="result-infos__container loading">
    <StyledCircularProgress size={size} />
  </Box>
);

export default Loader;
