import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import api from "../../utils/api/axios";
import { notifyApiSuccess } from "../../utils/api/notifyApi";

import Loader from "../../components/Loader";

import "./ValidEmail.css";

const ValidEmail = () => {
  const navigate = useNavigate();
  const { email, userId } = useParams();

  const validEmail = async () => {
    if (!email && !userId) return;
    try {
      const { data } = await api.post(`/auth/validate-email/`, {
        email,
        userId,
      });
      if (data) {
        notifyApiSuccess("Email validé");
        navigate("/");
      }
    } catch (error) {
      console.error("Error validating email", error);
    }
  };

  useEffect(() => {
    validEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, userId]);

  return (
    <div className="valid-email__container">
      <p className="valid-email__title">Validation de votre email...</p>
      <Loader />
    </div>
  );
};

export default ValidEmail;
