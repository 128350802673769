import { Button } from "@mui/material";

import { useAppSelector, useAppDispatch } from "../../store/hook";

import SettingsSection, { Cta } from "./Section";
import { UserLogin, UserRole } from "../../types/api/user";
import { useNavigate } from "react-router-dom";
import { setAuthUser } from "../../store/reducers/auth.slice";
import api from "../../utils/api/axios";
import { useEffect } from "react";
// import apiMercanet from "../../utils/mercanetApi/axios";

const MySubscription = () => {
  const navigate = useNavigate();
  const { isAuth, user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const getMe = async () => {
    try {
      const { data } = await api.get<UserLogin>("/auth/me");
      if (data) {
        dispatch(setAuthUser(data));
      }
    } catch (_) {}
  };

  useEffect(() => {
    if (isAuth) getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  if (!user) return null;

  // const sendPaymentRequest = async () => {
  //   // send Mercanet payment request
  //   try {
  //     const { data: preapredDataForMercanet } = await api.post(
  //       "/subscription/init"
  //     );
  //     console.log(preapredDataForMercanet);
  //     // const mercanetReturn = await apiMercanet.post(
  //     //   // "/rs-services/v2/paymentInit",
  //     //   // "/paymentInit",
  //     //   "/rs-services/v2/paymentInit",
  //     //   preapredDataForMercanet
  //     // );
  //     // console.log(mercanetReturn);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const { allAccess, role } = user;

  const isFreeWithAllAccesUser =
    allAccess &&
    [
      UserRole.ADMIN,
      UserRole.AUTHOR,
      UserRole.EDITOR,
      UserRole.MANAGER,
      UserRole.OLD_COLLABORATOR,
    ].includes(role);

  const ctaGetSubscription: Cta[] = [
    {
      children: "Activer mon abonnement pour 33,00€ TTC",
      onClick: () => navigate("/abonnement/facturation"),
      variant: "contained",
    },
  ];

  return (
    <SettingsSection
      title="Mon abonnement"
      cta={!allAccess ? ctaGetSubscription : undefined}
    >
      <p>
        {isFreeWithAllAccesUser
          ? "Vous avez un accès complet gratuit"
          : allAccess
          ? "Votre abonnement est actif"
          : "Vous n'avez pas d'abonnement actif"}
      </p>

      <div>
        <Button variant="outlined" onClick={() => {}}>
          Recevoir la lettre d'information ePOPI
        </Button>
      </div>
    </SettingsSection>
  );
};

export default MySubscription;
