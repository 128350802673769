import { toast } from "react-toastify";
import { z } from "zod";

const zApiSimpleError = z.object({
  error: z.string(),
});

type ApiSimpleError = z.infer<typeof zApiSimpleError>;

const isApiSimpleError = (input: unknown): input is ApiSimpleError =>
  zApiSimpleError.safeParse(input).success;

const getErrorMessage = (error: unknown, backupMessage?: string) => {
  if (typeof error === "string" && error !== "") {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (isApiSimpleError(error)) {
    return error.error;
  }

  return backupMessage || "Une erreur est survenue";
};

export const notifyApiError = (error: unknown, backupMessage?: string) => {
  const text = getErrorMessage(error, backupMessage);
  toast(text, {
    type: "error",
    position: "bottom-right",
    style: { bottom: 24 },
  });
};

export const notifyApiSuccess = (message: string) => {
  toast(message, {
    type: "success",
    position: "bottom-right",
    style: { bottom: 24 },
  });
};

export const notifyApiWarning = (message: string) => {
  toast(message, {
    type: "warning",
    position: "bottom-right",
    style: { bottom: 24 },
  });
};
