import { FormEvent } from "react";
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";
import dayjs from "dayjs";

import { setUserRegistrationStep1 } from "../../store/reducers/auth.slice";
import { userCountryLabels, userTitlesLabels } from "../../utils/user";
import { useAppDispatch } from "../../store/hook";
import { UserCountry, UserRegistration, UserTitle } from "../../types/user";

import StyledDatePicker from "../StyledDatePicker";

const schema = z.object({
  title: z.nativeEnum(UserTitle, { message: "Une civilité est requise" }),
  lastname: z
    .string({ message: "Nom requis" })
    .min(2, { message: "Nom requis" }),
  firstname: z
    .string({ message: "Prénom requis" })
    .min(2, { message: "Prénom requis" }),
  birthdate: z
    .string()
    .date()
    .or(z.string().refine((v) => !v)),
  country: z.nativeEnum(UserCountry, { message: "Un pays est requis" }),
});

type FormData = z.infer<typeof schema>;

interface Step1Props {
  userRegistration: Partial<UserRegistration> | null;
}

const Step1 = ({ userRegistration }: Step1Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { email, title, lastname, firstname, birthdate, country } =
    userRegistration ?? {};

  if (!email) {
    navigate("/connexion/inscription");
  }

  const { Field, Subscribe, handleSubmit } = useForm<
    FormData,
    typeof zodValidator
  >({
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      try {
        dispatch(setUserRegistrationStep1(value));
        navigate("/inscription/etape-2");
      } catch (_) {}
    },
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit();
  };

  return (
    <div className="form">
      <form className="form" onSubmit={submit}>
        <div className="input-container">
          <Field
            name="title"
            defaultValue={title}
            validators={{ onChange: schema.shape.title }}
            children={(field) => (
              <>
                <p className="input-label">Civilité*</p>
                <Select
                  id="select-titre"
                  size="small"
                  required
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) =>
                    field.handleChange(e.target.value as UserTitle)
                  }
                >
                  {userTitlesLabels.map((title) => (
                    <MenuItem key={title.value} value={title.value}>
                      {title.label}
                    </MenuItem>
                  ))}
                </Select>
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </>
            )}
          />
        </div>

        <div className="dual-input-container">
          <Field
            name="lastname"
            defaultValue={lastname ?? ""}
            validators={{ onChange: schema.shape.lastname }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Nom*</p>
                <TextField
                  required
                  size="small"
                  id="lastname"
                  type="text"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />
          <Field
            name="firstname"
            defaultValue={firstname ?? ""}
            validators={{ onChange: schema.shape.firstname }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Prénom*</p>
                <TextField
                  required
                  size="small"
                  id="firstname"
                  type="text"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />
        </div>

        <div className="input-container">
          <Field
            name="birthdate"
            defaultValue={birthdate ?? ""}
            validators={{ onChange: schema.shape.birthdate }}
            children={(field) => (
              <>
                <p className="input-label">Date de naissance</p>
                <StyledDatePicker
                  disableFuture
                  value={
                    field.state.value
                      ? dayjs(field.state.value as string)
                      : null
                  }
                  onChange={(e) =>
                    field.handleChange(e ? e?.format("YYYY-MM-DD") : "")
                  }
                />
              </>
            )}
          />
        </div>

        <div className="input-container">
          <Field
            name="country"
            defaultValue={country}
            // defaultValue={countryLabel ? countryLabel.value : undefined}
            validators={{ onChange: schema.shape.country }}
            children={(field) => (
              <>
                <p className="input-label">Pays*</p>
                <Autocomplete
                  id="country"
                  disableClearable
                  value={userCountryLabels.find(
                    (countryLabel) => countryLabel.value === field.state.value
                  )}
                  options={userCountryLabels}
                  getOptionLabel={(option) => option.value}
                  getOptionKey={(option) => option.value}
                  onChange={(_, value) => field.handleChange(value.value)}
                  renderOption={(props, country) => (
                    <MenuItem
                      {...props}
                      key={country.value}
                      value={country.value}
                    >
                      {country.label}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size="small"
                      error={!!field.state.meta.errors.length}
                    />
                  )}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </>
            )}
          />
        </div>

        <div className="registration-buttons-container">
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Annuler
          </Button>
          <Subscribe
            // @ts-ignore ignore build error
            selector={(state) => [state.canSubmit]}
            // @ts-ignore ignore build error
            children={([canSubmit]) => (
              <Button variant="contained" disabled={!canSubmit} type="submit">
                Suivant
              </Button>
            )}
          />
        </div>
      </form>
    </div>
  );
};

export default Step1;
