import { Fade, Modal } from "@mui/material";

interface CustomModalProps {
  open: boolean;
  onClose?: () => void;
  size?: "small" | "medium" | "large" | "fullWidth";
  children: React.ReactNode;
}

const CustomModal = ({
  open,
  onClose,
  size = "medium",
  children,
}: CustomModalProps) => {
  return (
    <Modal open={open} onClose={onClose} disableEnforceFocus disableAutoFocus>
      <Fade in={open}>
        <div className="modal-main-container">
          <div className={`modal-container ${size}`}>{children}</div>
        </div>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
