import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RecentSheet } from "../../types/api/sheet";

interface RecentSheetsState {
  recentSheets: RecentSheet[];
}

const initialState: RecentSheetsState = {
  recentSheets: [],
};

export const recentSheetsSlice = createSlice({
  name: "recentSheets",
  initialState,
  reducers: {
    setRecentSheets: (state, action: PayloadAction<RecentSheet[]>) => {
      state.recentSheets = action.payload;
    },
  },
});

export const { setRecentSheets } = recentSheetsSlice.actions;

export default recentSheetsSlice.reducer;
