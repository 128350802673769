import { styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const StyledDatePicker = styled(DatePicker)(() => ({
  // custom size small modification
  "& .MuiInputBase-input": {
    padding: "8.5px 0 8.5px 14px",
  },
  "& .MuiFormLabel-root": {
    top: "-7px",
    "&.MuiInputLabel-shrink": {
      top: "0px",
    },
  },
}));

export default StyledDatePicker;
