import { UserCountry, UserSpeciality, UserTitle } from "../user";

export enum UserRole {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  EDITOR = "EDITOR",
  AUTHOR = "AUTHOR",
  OLD_COLLABORATOR = "OLD_COLLABORATOR",
  INDIVIDUAL = "INDIVIDUAL",
  ESTABLISHMENT = "ESTABLISHMENT",
}

export type User = {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  title: UserTitle;
  role: UserRole;
  establishment?: string;
  birthdate?: string;
  service?: string;
  phone?: string;
  address?: string;
  city?: string;
  country?: UserCountry;
  speciality?: UserSpeciality;
  invoiceEstablishment?: string;
  invoiceService?: string;
  invoiceAddress?: string;
  invoiceZipCode?: string;
  invoiceCity?: string;
  invoiceCountry?: UserCountry;
  isVerified: boolean;
  allAccess: boolean;
  createAt: string;
  updatedAt: string;
};

type UserToken = {
  type: string;
  name: string | null;
  token: string;
  abilities: string[];
  lastUsedAt: string | null;
  expiresAt: string | null;
};

export type UserLogin = User & {
  token: UserToken;
};
