import { FormEvent, useState } from "react";
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";

import api from "../../utils/api/axios";
import { useAppSelector } from "../../store/hook";
import { userCountryLabels, userTitlesLabels } from "../../utils/user";
import { UserCountry, UserTitle } from "../../types/user";

import CustomCheckbox from "../../components/CustomCheckbox";

import "./Subscription.css";

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

const Section = ({ title, children }: SectionProps) => (
  <div className="subscription-section">
    <h5 className="subscription-section__title">{title}</h5>
    <div className="subscription-section__content">{children}</div>
  </div>
);

const schema = z.object({
  title: z.nativeEnum(UserTitle, { message: "Une civilité est requise" }),
  name: z
    .string({ message: "Prénom et nom requis" })
    .min(2, { message: "Prénom et nom requis" }),
  establishment: z.string().optional(),
  service: z.string().optional(),
  address: z.string({ message: "Adresse requise" }),
  zipCode: z.string({ message: "Code postal requis" }),
  city: z.string({ message: "Ville requise" }),
  country: z.nativeEnum(UserCountry, { message: "Un pays est requis" }),
  code: z.string().optional(),
  validGeneralConditions: z
    .boolean()
    .refine(Boolean, "Vous devez accepter les conditions générales"),
});

type FormData = z.infer<typeof schema>;

const Subscription = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);

  const [codeError, setCodeError] = useState<string | undefined>();

  const { Field, Subscribe, handleSubmit } = useForm<
    FormData,
    typeof zodValidator
  >({
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      // check code validation
      if (value.code) {
        try {
          setCodeError(undefined);
          if (value.code === "" || value.code === undefined) return;
          const { data } = await api.post<boolean>(
            `/code/validate/${value.code}`
          );
          if (data === false) {
            setCodeError("Le code est invalide");
            return;
          }
        } catch (_) {
          setCodeError("Impossible de valider le code");
        }
      }

      // save user subscription data (init)
      try {
        const { data: subscriptionReference } = await api.post<string>(
          "/subscription/init",
          value
        );
        navigate(`/abonnement/recapitulatif/${subscriptionReference}`);
      } catch (_) {
        console.error("Error saving user subscription data");
      }
    },
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit();
  };

  if (!user) return null;

  const {
    lastname,
    firstname,
    title,
    establishment,
    service,
    invoiceEstablishment,
    invoiceService,
    address,
    city,
    country,
    invoiceAddress,
    invoiceZipCode,
    invoiceCity,
    invoiceCountry,
  } = user;

  return (
    <div className="subscription-main">
      <form className="form" onSubmit={submit}>
        <Section title="Personne ou entité à facturer">
          <Field
            name="title"
            defaultValue={title}
            validators={{ onChange: schema.shape.title }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Civilité*</p>
                <Select
                  id="select-titre"
                  size="small"
                  required
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) =>
                    field.handleChange(e.target.value as UserTitle)
                  }
                >
                  {userTitlesLabels.map((title) => (
                    <MenuItem key={title.value} value={title.value}>
                      {title.label}
                    </MenuItem>
                  ))}
                </Select>
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />

          <Field
            name="name"
            defaultValue={`${firstname} ${lastname}`}
            validators={{ onChange: schema.shape.name }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Prénom nom*</p>
                <TextField
                  required
                  size="small"
                  id="name"
                  type="text"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />

          <Field
            name="establishment"
            defaultValue={invoiceEstablishment ?? establishment ?? ""}
            validators={{ onChange: schema.shape.establishment }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Établissement / Société</p>
                <TextField
                  size="small"
                  id="establishment"
                  type="text"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />

          <Field
            name="service"
            defaultValue={invoiceService ?? service ?? ""}
            validators={{ onChange: schema.shape.service }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Service</p>
                <TextField
                  size="small"
                  id="service"
                  type="text"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />
        </Section>

        <Section title="Informations de facturation">
          <Field
            name="address"
            defaultValue={invoiceAddress ?? address ?? ""}
            validators={{ onChange: schema.shape.address }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Adresse*</p>
                <TextField
                  required
                  size="small"
                  id="address"
                  type="text"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />

          <Field
            name="zipCode"
            defaultValue={invoiceZipCode ?? ""}
            validators={{ onChange: schema.shape.zipCode }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Code Postal</p>
                <TextField
                  required
                  size="small"
                  id="zipCode"
                  type="text"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />

          <Field
            name="city"
            defaultValue={invoiceCity ?? city ?? ""}
            validators={{ onChange: schema.shape.city }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Ville*</p>
                <TextField
                  required
                  size="small"
                  id="city"
                  type="text"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />

          <Field
            name="country"
            defaultValue={invoiceCountry ?? country}
            validators={{ onChange: schema.shape.country }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Pays*</p>
                <Autocomplete
                  id="country"
                  disableClearable
                  value={userCountryLabels.find(
                    (countryLabel) => countryLabel.value === field.state.value
                  )}
                  options={userCountryLabels}
                  getOptionLabel={(option) => option.value}
                  getOptionKey={(option) => option.value}
                  onChange={(_, value) => field.handleChange(value.value)}
                  renderOption={(props, country) => (
                    <MenuItem
                      {...props}
                      key={country.value}
                      value={country.value}
                    >
                      {country.label}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      size="small"
                      error={!!field.state.meta.errors.length}
                    />
                  )}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />
        </Section>

        <Section title="Code avantage">
          <Field
            name="code"
            validators={{ onChange: schema.shape.code }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Code avantage</p>
                <TextField
                  size="small"
                  id="code"
                  type="text"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length || !!codeError}
                  onBlur={field.handleBlur}
                  onChange={(e) => {
                    field.handleChange(e.target.value);
                    setCodeError(undefined);
                  }}
                />
                {codeError && <em className="input-error">{codeError}</em>}
              </div>
            )}
          />
        </Section>

        <Section title="Validation de l'utilisateur">
          <Field
            name="validGeneralConditions"
            defaultValue={false}
            validators={{ onChange: schema.shape.validGeneralConditions }}
            children={(field) => (
              <div className="input-container">
                <CustomCheckbox
                  id="validGeneralConditions"
                  size="small"
                  required
                  label={
                    <span>
                      En cochant cette case, je déclare avoir accepté{" "}
                      <Link to="/conditions-generales">
                        les conditions générales d'utilisation du service ePopi
                      </Link>
                    </span>
                  }
                  checked={field.state.value}
                  onChange={(e) => field.handleChange(e.target.checked)}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />
        </Section>

        <Subscribe
          // @ts-ignore ignore build error
          selector={(state) => [state.canSubmit]}
          // @ts-ignore ignore build error
          children={([canSubmit]) => (
            <Button
              sx={{ width: "min-content", textWrap: "nowrap" }}
              variant="contained"
              disabled={!canSubmit}
              size="large"
              type="submit"
            >
              Payer mon abonnement
            </Button>
          )}
        />
      </form>
    </div>
  );
};

export default Subscription;
