import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

import Header from "../../components/main/Header";
import Footer from "../../components/main/Footer";

import "./Root.css";

const Root = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";

  return (
    <Box id="main">
      <Header />
      <div id="content" className={!isHome ? "content" : ""}>
        <Outlet />
      </div>
      <Footer />
    </Box>
  );
};

export default Root;
