import { Tab, Tabs, styled } from "@mui/material";
import { useState } from "react";

import epopiOnSmartphoneMockup from "../../assets/images/epopi-on-smartphone-mockup.png";
import settingsItemIcon from "../../assets/icons/settings-item.svg";

const StyledTabs = styled(Tabs)({
  width: "100%",
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
});

export interface StyledTabProps {
  label: string;
  isFirst?: boolean;
  isLast?: boolean;
}

const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />, {
  shouldForwardProp: (prop) => prop !== "isFirst" && prop !== "isLast",
})(({ isFirst, isLast }) => ({
  fontSize: 16,
  fontWeight: 600,
  padding: "8px 24px",
  color: "var(--navy)",
  borderTopLeftRadius: isFirst ? "8px" : "0px",
  borderTopRightRadius: isLast ? "8px" : "0px",
  borderBottomLeftRadius: isFirst ? "8px" : "0px",
  borderBottomRightRadius: isLast ? "8px" : "0px",
  border: "1px solid var(--light-grey)",
  backgroundColor: "white",
  transition: "all 0.2s",
  "&.Mui-selected": {
    color: "white",
    border: "1px solid var(--navy)",
    backgroundColor: "var(--navy)",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
}));

interface StepProps {
  label: string;
  description: string;
  number: number;
}

const Step = ({ label, description, number }: StepProps) => {
  return (
    <div className="on-smartphone__step">
      <img
        className="on-smartphone__step--icon"
        src={settingsItemIcon}
        alt="settings"
      />
      <p className="on-smartphone__step--icon__number">{number}</p>

      {/* <div className="on-smartphone__step--icon__container">
      </div> */}

      <div className="on-smartphone__step--content">
        <h5 className="on-smartphone__step--label">{label}</h5>
        <p className="on-smartphone__step--number">{description}</p>
      </div>
    </div>
  );
};

const EpopiOnSmartphone = () => {
  const tabs = [{ label: "iOS" }, { label: "Android" }];
  const [tab, setTab] = useState(0);

  return (
    <div className="on-smartphone content">
      <div className="on-smartphone__header">
        <h2 className="on-smartphone__title">
          Utilisez ePOPI sur votre smartphone
        </h2>
        <p className="on-smartphone__description">
          ePOPI peut être consulté en ligne ou hors ligne sur votre smartphone
          grâce à la web application. Suivez les consignes ci-dessous pour
          télécharger l'icône ePOPI.
        </p>
      </div>

      <div className="on-smartphone__content">
        <div className="on-smartphone__tabs">
          <StyledTabs
            value={tab}
            onChange={(_, newValue) => setTab(newValue)}
            variant="fullWidth"
          >
            {tabs.map((tab, index) => (
              <StyledTab
                key={index}
                label={tab.label}
                isFirst={index === 0}
                isLast={index === tabs.length - 1}
              />
            ))}
          </StyledTabs>
        </div>

        <div className="on-smartphone__steps">
          <div className="on-smartphone__steps-container">
            <Step
              label="Aller sur epopi.fr"
              description="Ouvrez le navigateur de votre choix sur votre téléphone."
              number={1}
            />
            <Step
              label="Accédez au menu d'options"
              description={
                tab === 0
                  ? "Sur iOS, avec Safari, appuyez sur l'icône de partage au bas de l'écran."
                  : "Sur Android, en utilisant Google Chrome, appuyez sur les trois points verticaux dans le coin supérieur droit pour ouvrir le menu."
              }
              number={2}
            />
          </div>
          <div className="on-smartphone__fake-mockup" />
          <div className="on-smartphone__steps-container">
            <Step
              label="Trouvez l'option d'ajout à l'écran d'accueil"
              description={
                tab === 0
                  ? "Sur iOS (Safari), faites défiler vers le bas dans le menu de partage pour trouver l'option « Sur l'écran d'accueil »."
                  : "Sur Android (Chrome), cherchez une option nommée « Ajouter à l'écran d'accueil » ou quelque chose de similaire dans le menu."
              }
              number={3}
            />
            <Step
              label="Confirmez l'ajout"
              description="Une icône représentant le site web apparaîtra sur l'écran d'accueil de votre téléphone, fonctionnant comme une application native."
              number={4}
            />
          </div>
          <div className="on-smartphone__mockup-container">
            <img
              className="on-smartphone__mockup--image"
              src={epopiOnSmartphoneMockup}
              alt="ePOPI sur smartphone"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EpopiOnSmartphone;
