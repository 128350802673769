import { FormEvent, useState } from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";

import api, { setToken } from "../../utils/api/axios";
import { notifyApiSuccess } from "../../utils/api/notifyApi";
import { UserLogin } from "../../types/api/user";
import { useAppDispatch } from "../../store/hook";
import { setAuthUser } from "../../store/reducers/auth.slice";
import ResetPasswordDialog from "./ResetPasswordDialog";

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

  const { Field, Subscribe, handleSubmit } = useForm({
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      try {
        const userInfos = await api.post<UserLogin>("/auth/login", value);
        notifyApiSuccess("Connexion réussie");
        dispatch(setAuthUser(userInfos.data));
        setToken(userInfos.data.token.token);
        navigate("/");
      } catch (_) {
        return { errors: "Email ou mot de passe incorrect" };
      }
    },
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit();
  };

  return (
    <form className="form" onSubmit={(e) => submit(e)}>
      <div className="input-container">
        <Field
          name="email"
          defaultValue=""
          validators={{
            onChange: z.string().email("Renseignez un email valide"),
          }}
          children={(field) => (
            <>
              <p className="input-label">Email*</p>
              <TextField
                type="text"
                placeholder="Votre email"
                autoComplete="email"
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </>
          )}
        />
      </div>
      <div className="input-container">
        <Field
          name="password"
          defaultValue=""
          validators={{
            onChange: z
              .string()
              .min(6, "Le mot de passe doit contenir au moins 6 caractères"),
          }}
          children={(field) => (
            <>
              <p className="input-label">Mot de passe*</p>
              <TextField
                type="password"
                placeholder="••••••••"
                autoComplete="current-password"
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </>
          )}
        />
      </div>

      <Button
        onClick={() => setOpenResetPasswordDialog(true)}
        className="forgot-password"
      >
        Mot de passe oublié ?
      </Button>

      <Subscribe
        // @ts-ignore ignore build error
        selector={(state) => [state.canSubmit, state.isSubmitting]}
        // @ts-ignore ignore build error
        children={([canSubmit]) => (
          <Button
            sx={{
              backgroundColor: "#000",
              color: "white",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
            variant="contained"
            disabled={!canSubmit}
            type="submit"
            size="large"
          >
            Connexion
          </Button>
        )}
      />

      <ResetPasswordDialog
        open={openResetPasswordDialog}
        onClose={() => setOpenResetPasswordDialog(false)}
      />
    </form>
  );
};

export default Login;
