import { UserCountry, UserSpeciality, UserTitle } from "../types/user";

export const userTitlesLabels = [
  { label: "M.", value: UserTitle.MR },
  { label: "Mme.", value: UserTitle.MS },
];

export const userSpecialitiesLabels = [
  {
    label: "Anesthésiste-réanimateur",
    value: UserSpeciality["Anesthésiste-réanimateur"],
  },
  { label: "Biologiste", value: UserSpeciality["Biologiste"] },
  { label: "Cardiologue", value: UserSpeciality["Cardiologue"] },
  { label: "Chirurgien", value: UserSpeciality["Chirurgien"] },
  { label: "Étudiant", value: UserSpeciality["Étudiant"] },
  { label: "Gastro-entérologue", value: UserSpeciality["Gastro-entérologue"] },
  { label: "Gériatre", value: UserSpeciality["Gériatre"] },
  { label: "Généraliste", value: UserSpeciality["Généraliste"] },
  { label: "Hygiéniste", value: UserSpeciality["Hygiéniste"] },
  { label: "Interne MIT", value: UserSpeciality["Interne MIT"] },
  {
    label: "Interne toutes spécialités",
    value: UserSpeciality["Interne toutes spécialités"],
  },
  {
    label: "Maladies infectieuses",
    value: UserSpeciality["Maladies infectieuses"],
  },
  { label: "Médecine interne", value: UserSpeciality["Médecine interne"] },
  { label: "Microbiologiste", value: UserSpeciality["Microbiologiste"] },
  { label: "Néphrologue", value: UserSpeciality["Néphrologue"] },
  { label: "Oncologue", value: UserSpeciality["Oncologue"] },
  { label: "Pédiatre", value: UserSpeciality["Pédiatre"] },
  { label: "Pharmacien", value: UserSpeciality["Pharmacien"] },
  { label: "Pneumologue", value: UserSpeciality["Pneumologue"] },
  {
    label: "Médecine physique et réadaptation",
    value: UserSpeciality["Médecine physique et réadaptation"],
  },
  {
    label: "Médecin réanimateur",
    value: UserSpeciality["Médecin réanimateur"],
  },
  {
    label: "Médecine d’urgences",
    value: UserSpeciality["Médecine d’urgences"],
  },
  { label: "Autre...", value: UserSpeciality["Autre..."] },
];

export const userCountryLabels = [
  { label: "Afghanistan", value: UserCountry.AFGHANISTAN },
  { label: "Albanie", value: UserCountry.ALBANIA },
  { label: "Algérie", value: UserCountry.ALGERIA },
  { label: "Andorre", value: UserCountry.ANDORRA },
  { label: "Angola", value: UserCountry.ANGOLA },
  { label: "Antigua et Barbuda", value: UserCountry.ANTIGUA_AND_BARBUDA },
  { label: "Argentine", value: UserCountry.ARGENTINA },
  { label: "Arménie", value: UserCountry.ARMENIA },
  { label: "Australie", value: UserCountry.AUSTRALIA },
  { label: "Autriche", value: UserCountry.AUSTRIA },
  { label: "Azerbaïdjan", value: UserCountry.AZERBAIJAN },
  { label: "Bahamas", value: UserCountry.BAHAMAS },
  { label: "Bahreïn", value: UserCountry.BAHRAIN },
  { label: "Bangladesh", value: UserCountry.BANGLADESH },
  { label: "Barbade", value: UserCountry.BARBADOS },
  { label: "Bélarus", value: UserCountry.BELARUS },
  { label: "Belgique", value: UserCountry.BELGIUM },
  { label: "Belize", value: UserCountry.BELIZE },
  { label: "Bénin", value: UserCountry.BENIN },
  { label: "Bhoutan", value: UserCountry.BHUTAN },
  { label: "Bolivie", value: UserCountry.BOLIVIA },
  { label: "Bosnie-Herzégovine", value: UserCountry.BOSNIA_AND_HERZEGOVINA },
  { label: "Botswana", value: UserCountry.BOTSWANA },
  { label: "Brésil", value: UserCountry.BRAZIL },
  { label: "Brunéi", value: UserCountry.BRUNEI },
  { label: "Bulgarie", value: UserCountry.BULGARIA },
  { label: "Burkina Faso", value: UserCountry.BURKINA_FASO },
  { label: "Burundi", value: UserCountry.BURUNDI },
  { label: "Cap-Vert", value: UserCountry.CABO_VERDE },
  { label: "Cambodge", value: UserCountry.CAMBODIA },
  { label: "Cameroun", value: UserCountry.CAMEROON },
  { label: "Canada", value: UserCountry.CANADA },
  {
    label: "République centrafricaine",
    value: UserCountry.CENTRAL_AFRICAN_REPUBLIC,
  },
  { label: "Tchad", value: UserCountry.CHAD },
  { label: "Chili", value: UserCountry.CHILE },
  { label: "Chine", value: UserCountry.CHINA },
  { label: "Colombie", value: UserCountry.COLOMBIA },
  { label: "Comores", value: UserCountry.COMOROS },
  { label: "Congo", value: UserCountry.CONGO },
  { label: "Costa Rica", value: UserCountry.COSTA_RICA },
  { label: "Croatie", value: UserCountry.CROATIA },
  { label: "Cuba", value: UserCountry.CUBA },
  { label: "Chypre", value: UserCountry.CYPRUS },
  { label: "République tchèque", value: UserCountry.CZECH_REPUBLIC },
  { label: "Danemark", value: UserCountry.DENMARK },
  { label: "Djibouti", value: UserCountry.DJIBOUTI },
  { label: "Dominique", value: UserCountry.DOMINICA },
  { label: "République dominicaine", value: UserCountry.DOMINICAN_REPUBLIC },
  { label: "Timor oriental", value: UserCountry.EAST_TIMOR },
  { label: "Équateur", value: UserCountry.ECUADOR },
  { label: "Égypte", value: UserCountry.EGYPT },
  { label: "Salvador", value: UserCountry.EL_SALVADOR },
  { label: "Guinée équatoriale", value: UserCountry.EQUATORIAL_GUINEA },
  { label: "Érythrée", value: UserCountry.ERITREA },
  { label: "Estonie", value: UserCountry.ESTONIA },
  { label: "Eswatini", value: UserCountry.ESWATINI },
  { label: "Éthiopie", value: UserCountry.ETHIOPIA },
  { label: "Fidji", value: UserCountry.FIJI },
  { label: "Finlande", value: UserCountry.FINLAND },
  { label: "France", value: UserCountry.FRANCE },
  { label: "Gabon", value: UserCountry.GABON },
  { label: "Gambie", value: UserCountry.GAMBIA },
  { label: "Géorgie", value: UserCountry.GEORGIA },
  { label: "Allemagne", value: UserCountry.GERMANY },
  { label: "Ghana", value: UserCountry.GHANA },
  { label: "Grèce", value: UserCountry.GREECE },
  { label: "Grenade", value: UserCountry.GRENADA },
  { label: "Guatemala", value: UserCountry.GUATEMALA },
  { label: "Guinée", value: UserCountry.GUINEA },
  { label: "Guinée-Bissau", value: UserCountry.GUINEA_BISSAU },
  { label: "Guyana", value: UserCountry.GUYANA },
  { label: "Haïti", value: UserCountry.HAITI },
  { label: "Honduras", value: UserCountry.HONDURAS },
  { label: "Hongrie", value: UserCountry.HUNGARY },
  { label: "Islande", value: UserCountry.ICELAND },
  { label: "Inde", value: UserCountry.INDIA },
  { label: "Indonésie", value: UserCountry.INDONESIA },
  { label: "Iran", value: UserCountry.IRAN },
  { label: "Irak", value: UserCountry.IRAQ },
  { label: "Irlande", value: UserCountry.IRELAND },
  { label: "Israël", value: UserCountry.ISRAEL },
  { label: "Italie", value: UserCountry.ITALY },
  { label: "Côte d'Ivoire", value: UserCountry.IVORY_COAST },
  { label: "Jamaïque", value: UserCountry.JAMAICA },
  { label: "Japon", value: UserCountry.JAPAN },
  { label: "Jordanie", value: UserCountry.JORDAN },
  { label: "Kazakhstan", value: UserCountry.KAZAKHSTAN },
  { label: "Kenya", value: UserCountry.KENYA },
  { label: "Kiribati", value: UserCountry.KIRIBATI },
  { label: "Corée du Nord", value: UserCountry.KOREA_NORTH },
  { label: "Corée du Sud", value: UserCountry.KOREA_SOUTH },
  { label: "Kosovo", value: UserCountry.KOSOVO },
  { label: "Koweït", value: UserCountry.KUWAIT },
  { label: "Kirghizistan", value: UserCountry.KYRGYZSTAN },
  { label: "Laos", value: UserCountry.LAOS },
  { label: "Lettonie", value: UserCountry.LATVIA },
  { label: "Liban", value: UserCountry.LEBANON },
  { label: "Lesotho", value: UserCountry.LESOTHO },
  { label: "Libéria", value: UserCountry.LIBERIA },
  { label: "Libye", value: UserCountry.LIBYA },
  { label: "Liechtenstein", value: UserCountry.LIECHTENSTEIN },
  { label: "Lituanie", value: UserCountry.LITHUANIA },
  { label: "Luxembourg", value: UserCountry.LUXEMBOURG },
  { label: "Madagascar", value: UserCountry.MADAGASCAR },
  { label: "Malawi", value: UserCountry.MALAWI },
  { label: "Malaisie", value: UserCountry.MALAYSIA },
  { label: "Maldives", value: UserCountry.MALDIVES },
  { label: "Mali", value: UserCountry.MALI },
  { label: "Malte", value: UserCountry.MALTA },
  { label: "Îles Marshall", value: UserCountry.MARSHALL_ISLANDS },
  { label: "Mauritanie", value: UserCountry.MAURITANIA },
  { label: "Maurice", value: UserCountry.MAURITIUS },
  { label: "Mexique", value: UserCountry.MEXICO },
  { label: "Micronésie", value: UserCountry.MICRONESIA },
  { label: "Moldavie", value: UserCountry.MOLDOVA },
  { label: "Monaco", value: UserCountry.MONACO },
  { label: "Mongolie", value: UserCountry.MONGOLIA },
  { label: "Monténégro", value: UserCountry.MONTENEGRO },
  { label: "Maroc", value: UserCountry.MOROCCO },
  { label: "Mozambique", value: UserCountry.MOZAMBIQUE },
  { label: "Myanmar", value: UserCountry.MYANMAR },
  { label: "Namibie", value: UserCountry.NAMIBIA },
  { label: "Nauru", value: UserCountry.NAURU },
  { label: "Népal", value: UserCountry.NEPAL },
  { label: "Pays-Bas", value: UserCountry.NETHERLANDS },
  { label: "Nouvelle-Zélande", value: UserCountry.NEW_ZEALAND },
  { label: "Nicaragua", value: UserCountry.NICARAGUA },
  { label: "Niger", value: UserCountry.NIGER },
  { label: "Nigéria", value: UserCountry.NIGERIA },
  { label: "Macédoine du Nord", value: UserCountry.NORTH_MACEDONIA },
  { label: "Norvège", value: UserCountry.NORWAY },
  { label: "Oman", value: UserCountry.OMAN },
  { label: "Pakistan", value: UserCountry.PAKISTAN },
  { label: "Palaos", value: UserCountry.PALAU },
  { label: "Palestine", value: UserCountry.PALESTINE },
  { label: "Panama", value: UserCountry.PANAMA },
  { label: "Papouasie-Nouvelle-Guinée", value: UserCountry.PAPUA_NEW_GUINEA },
  { label: "Paraguay", value: UserCountry.PARAGUAY },
  { label: "Pérou", value: UserCountry.PERU },
  { label: "Philippines", value: UserCountry.PHILIPPINES },
  { label: "Pologne", value: UserCountry.POLAND },
  { label: "Portugal", value: UserCountry.PORTUGAL },
  { label: "Qatar", value: UserCountry.QATAR },
  { label: "Roumanie", value: UserCountry.ROMANIA },
  { label: "Russie", value: UserCountry.RUSSIA },
  { label: "Rwanda", value: UserCountry.RWANDA },
  { label: "Saint-Kitts-et-Nevis", value: UserCountry.SAINT_KITTS_AND_NEVIS },
  { label: "Sainte-Lucie", value: UserCountry.SAINT_LUCIA },
  {
    label: "Saint-Vincent-et-les-Grenadines",
    value: UserCountry.SAINT_VINCENT_AND_THE_GRENADINES,
  },
  { label: "Samoa", value: UserCountry.SAMOA },
  { label: "Saint-Marin", value: UserCountry.SAN_MARINO },
  { label: "Sao Tomé-et-Principe", value: UserCountry.SAO_TOME_AND_PRINCIPE },
  { label: "Arabie saoudite", value: UserCountry.SAUDI_ARABIA },
  { label: "Sénégal", value: UserCountry.SENEGAL },
  { label: "Serbie", value: UserCountry.SERBIA },
  { label: "Seychelles", value: UserCountry.SEYCHELLES },
  { label: "Sierra Leone", value: UserCountry.SIERRA_LEONE },
  { label: "Singapour", value: UserCountry.SINGAPORE },
  { label: "Slovaquie", value: UserCountry.SLOVAKIA },
  { label: "Slovénie", value: UserCountry.SLOVENIA },
  { label: "Îles Salomon", value: UserCountry.SOLOMON_ISLANDS },
  { label: "Somalie", value: UserCountry.SOMALIA },
  { label: "Afrique du Sud", value: UserCountry.SOUTH_AFRICA },
  { label: "Soudan du Sud", value: UserCountry.SOUTH_SUDAN },
  { label: "Espagne", value: UserCountry.SPAIN },
  { label: "Sri Lanka", value: UserCountry.SRI_LANKA },
  { label: "Soudan", value: UserCountry.SUDAN },
  { label: "Suriname", value: UserCountry.SURINAME },
  { label: "Suède", value: UserCountry.SWEDEN },
  { label: "Suisse", value: UserCountry.SWITZERLAND },
  { label: "Syrie", value: UserCountry.SYRIA },
  { label: "Taïwan", value: UserCountry.TAIWAN },
  { label: "Tadjikistan", value: UserCountry.TAJIKISTAN },
  { label: "Tanzanie", value: UserCountry.TANZANIA },
  { label: "Thaïlande", value: UserCountry.THAILAND },
  { label: "Togo", value: UserCountry.TOGO },
  { label: "Tonga", value: UserCountry.TONGA },
  { label: "Trinité-et-Tobago", value: UserCountry.TRINIDAD_AND_TOBAGO },
  { label: "Tunisie", value: UserCountry.TUNISIA },
  { label: "Turquie", value: UserCountry.TURKEY },
  { label: "Turkménistan", value: UserCountry.TURKMENISTAN },
  { label: "Tuvalu", value: UserCountry.TUVALU },
  { label: "Ouganda", value: UserCountry.UGANDA },
  { label: "Ukraine", value: UserCountry.UKRAINE },
  { label: "Émirats arabes unis", value: UserCountry.UNITED_ARAB_EMIRATES },
  { label: "Royaume-Uni", value: UserCountry.UNITED_KINGDOM },
  { label: "États-Unis", value: UserCountry.UNITED_STATES },
  { label: "Uruguay", value: UserCountry.URUGUAY },
  { label: "Ouzbékistan", value: UserCountry.UZBEKISTAN },
  { label: "Vanuatu", value: UserCountry.VANUATU },
  { label: "Vatican", value: UserCountry.VATICAN_CITY },
  { label: "Venezuela", value: UserCountry.VENEZUELA },
  { label: "Vietnam", value: UserCountry.VIETNAM },
  { label: "Yémen", value: UserCountry.YEMEN },
  { label: "Zambie", value: UserCountry.ZAMBIA },
  { label: "Zimbabwe", value: UserCountry.ZIMBABWE },
];
