import { useState } from "react";
import { Autocomplete, MenuItem, TextField } from "@mui/material";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useForm } from "@tanstack/react-form";
import { z } from "zod";
import dayjs from "dayjs";

import api from "../../utils/api/axios";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { setUser } from "../../store/reducers/auth.slice";
import { userCountryLabels, userSpecialitiesLabels } from "../../utils/user";
import { UserCountry, UserSpeciality } from "../../types/user";

import SettingsSection, { Cta } from "./Section";
import StyledDatePicker from "../StyledDatePicker";
import EditPasswordDialog from "./EditPasswordDialog";

const schema = z.object({
  firstname: z
    .string({ message: "Prénom requis" })
    .min(2, { message: "Prénom requis" }),
  lastname: z
    .string({ message: "Nom requis" })
    .min(2, { message: "Nom requis" }),
  email: z
    .string({ message: "Email requis" })
    .email("Renseignez un email valide"),
  birthdate: z
    .string()
    .date("Renseignez une date valide")
    .or(z.string().refine((v) => !v)),
  speciality: z.nativeEnum(UserSpeciality, {
    message: "Une spécialité est requise",
  }),
  establishment: z.string({ message: "Champ requis" }),
  service: z.string({ message: "Service requis" }),
  city: z.string({ message: "Ville requise" }),
  country: z.nativeEnum(UserCountry, { message: "Un pays est requis" }),
  phone: z.string().optional(),
});

type FormData = z.infer<typeof schema>;

const MyInfos = () => {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const [edit, setEdit] = useState(false);
  const [passwordEdit, setPasswordEdit] = useState(false);

  const { Field, handleSubmit } = useForm<FormData, typeof zodValidator>({
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      try {
        if (!user) return;
        await api.put(`/profile/infos/${user.id}`, value);
        dispatch(
          setUser({
            ...user,
            ...value,
          })
        );
        setEdit(false);
      } catch (_) {}
    },
  });

  const ctaEditInvoicingInfos: Cta[] = [
    {
      children: "Modifier mon mot de passe",
      onClick: () => setPasswordEdit(true),
      variant: "outlined",
    },
    edit
      ? {
          children: "Enregister mes changements",
          onClick: () => handleSubmit(),
          variant: "contained",
          type: "submit",
        }
      : {
          children: "Modifier mes infos",
          onClick: () => setEdit(!edit),
          variant: "contained",
          type: "button",
        },
  ];

  if (!user) return null;

  const {
    firstname,
    lastname,
    email,
    birthdate,
    speciality,
    establishment,
    service,
    city,
    country,
    phone,
  } = user;

  return (
    <SettingsSection
      title="Mes informations personnelles"
      cta={ctaEditInvoicingInfos}
    >
      <div className="dual-input-container">
        <Field
          name="lastname"
          defaultValue={lastname}
          validators={{ onChange: schema.shape.lastname }}
          children={(field) => (
            <div className="input-container">
              <p className="input-label">Nom*</p>
              <TextField
                size="small"
                id="lastname"
                type="text"
                disabled={!edit}
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            </div>
          )}
        />
        <Field
          name="firstname"
          defaultValue={firstname}
          validators={{ onChange: schema.shape.firstname }}
          children={(field) => (
            <div className="input-container">
              <p className="input-label">Prénom*</p>
              <TextField
                size="small"
                id="firstname"
                type="text"
                disabled={!edit}
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            </div>
          )}
        />
      </div>

      <div className="dual-input-container">
        <Field
          name="email"
          defaultValue={email}
          validators={{ onChange: schema.shape.email }}
          children={(field) => (
            <div className="input-container">
              <p className="input-label">Email*</p>
              <TextField
                size="small"
                id="email"
                type="text"
                disabled={!edit}
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </div>
          )}
        />

        <Field
          name="birthdate"
          defaultValue={birthdate ?? ""}
          validators={{ onChange: schema.shape.birthdate }}
          children={(field) => (
            <div className="input-container">
              <p className="input-label">Date de naissance</p>
              <StyledDatePicker
                disableFuture
                disabled={!edit}
                value={
                  field.state.value ? dayjs(field.state.value as string) : null
                }
                onChange={(e) =>
                  field.handleChange(e ? e?.format("YYYY-MM-DD") : "")
                }
              />
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </div>
          )}
        />
      </div>

      <div className="dual-input-container">
        <div className="input-container">
          <Field
            name="speciality"
            defaultValue={speciality}
            validators={{ onChange: schema.shape.speciality }}
            children={(field) => (
              <>
                <p className="input-label">Spécialité*</p>
                <Autocomplete
                  id="speciality"
                  disabled={!edit}
                  disableClearable
                  value={userSpecialitiesLabels.find(
                    (specialityLabel) =>
                      specialityLabel.value === field.state.value
                  )}
                  options={userSpecialitiesLabels}
                  getOptionLabel={(option) => option.value}
                  getOptionKey={(option) => option.value}
                  onChange={(_, value) =>
                    field.handleChange(value?.value as UserSpeciality)
                  }
                  renderOption={(props, speciality) => (
                    <MenuItem
                      {...props}
                      key={speciality.value}
                      value={speciality.value}
                    >
                      {speciality.label}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={!!field.state.meta.errors.length}
                    />
                  )}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </>
            )}
          />
        </div>
        <Field
          name="establishment"
          defaultValue={establishment ?? ""}
          validators={{ onChange: schema.shape.establishment }}
          children={(field) => (
            <div className="input-container">
              <p className="input-label">Établissement / Société*</p>
              <TextField
                size="small"
                id="establishment"
                type="text"
                disabled={!edit}
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </div>
          )}
        />
      </div>

      <div className="dual-input-container">
        <Field
          name="service"
          defaultValue={service ?? ""}
          validators={{ onChange: schema.shape.service }}
          children={(field) => (
            <div className="input-container">
              <p className="input-label">Service*</p>
              <TextField
                size="small"
                id="service"
                type="text"
                disabled={!edit}
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </div>
          )}
        />
        <Field
          name="city"
          defaultValue={city ?? ""}
          validators={{ onChange: schema.shape.city }}
          children={(field) => (
            <div className="input-container">
              <p className="input-label">Ville</p>
              <TextField
                size="small"
                id="city"
                type="text"
                disabled={!edit}
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </div>
          )}
        />
      </div>

      <div className="dual-input-container">
        <div className="input-container">
          <Field
            name="country"
            defaultValue={country}
            validators={{ onChange: schema.shape.country }}
            children={(field) => (
              <>
                <p className="input-label">Pays*</p>
                <Autocomplete
                  id="country"
                  disabled={!edit}
                  disableClearable
                  value={userCountryLabels.find(
                    (countryLabel) => countryLabel.value === field.state.value
                  )}
                  options={userCountryLabels}
                  getOptionLabel={(option) => option.value}
                  getOptionKey={(option) => option.value}
                  onChange={(_, value) =>
                    field.handleChange(value?.value as UserCountry)
                  }
                  renderOption={(props, country) => (
                    <MenuItem
                      {...props}
                      key={country.value}
                      value={country.value}
                    >
                      {country.label}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={!!field.state.meta.errors.length}
                    />
                  )}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </>
            )}
          />
        </div>
        <Field
          name="phone"
          defaultValue={phone ?? ""}
          validators={{ onChange: schema.shape.phone }}
          children={(field) => (
            <div className="input-container">
              <p className="input-label">Téléphone</p>
              <TextField
                size="small"
                id="phone"
                type="text"
                disabled={!edit}
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </div>
          )}
        />
      </div>

      <EditPasswordDialog
        open={passwordEdit}
        onClose={() => setPasswordEdit(false)}
      />
    </SettingsSection>
  );
};

export default MyInfos;
