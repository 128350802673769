import { Menu } from "@mui/material";
import { Link } from "react-router-dom";

export interface HeaderMenuItem {
  label: string;
  to: string;
  externalLink?: boolean;
  icon?: React.ReactNode | string;
  description?: string;
}

export interface HeaderMenuSection {
  title?: string;
  items: HeaderMenuItem[];
}

interface HeaderMenuProps {
  sections: HeaderMenuSection[];
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
}

const MenuItemWrapper = ({ externalLink, href, children, onClick }: any) =>
  externalLink ? (
    <a
      className="header-menu-section--item"
      href={href}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <Link to={href} className="header-menu-section--item" onClick={onClick}>
      {children}
    </Link>
  );

export const HeaderMenuItems = ({
  label,
  to,
  externalLink,
  icon,
  description,
  onClick,
}: HeaderMenuItem & { onClick: () => void }) => (
  <MenuItemWrapper externalLink={externalLink} href={to} onClick={onClick}>
    {icon && typeof icon === "string" ? (
      <img className="header-menu-section--item__icon" src={icon} alt="icon" />
    ) : (
      icon && icon
    )}
    <div className="header-menu-section--item__content">
      <p className="header-menu-section--item__label">{label}</p>
      {description && (
        <p className="header-menu-section--item__description">{description}</p>
      )}
    </div>
  </MenuItemWrapper>
);

const HeaderMenu = ({
  sections,
  anchorEl,
  open,
  handleClose,
}: HeaderMenuProps) => (
  <Menu
    id="header-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    elevation={0}
    PaperProps={{
      sx: {
        overflow: "visible",
        borderRadius: "8px",
        marginTop: "8px",
        minWidth: 300,
        padding: "24px 20px",
        border: "1px solid var(--gray-200)",
        boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
      },
    }}
    transformOrigin={{ horizontal: "center", vertical: "top" }}
    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
  >
    <div className="header-menu-container">
      {sections.map((section, index) => (
        <div key={index} className="header-menu-section">
          {section.title && (
            <p className="header-menu-section--title">{section.title}</p>
          )}

          {section.items.map((item, index) => (
            <HeaderMenuItems key={index} {...item} onClick={handleClose} />
          ))}
        </div>
      ))}
    </div>
  </Menu>
);

export default HeaderMenu;
