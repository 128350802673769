import { ReactNode } from "react";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  styled,
} from "@mui/material";

const StyledCheckbox = styled(Checkbox)({
  color: "var(--navy)",
  "& .MuiSvgIcon-root": {
    borderRadius: 8,
    fontSize: 21,
    color: "var(--navy)",
  },
});

const StyledFormControlLabel = styled(FormControlLabel)({
  ".MuiFormControlLabel-label": {
    fontSize: 14,
    color: "var(--gray-700)",
  },
});

type CustomCheckboxProps = CheckboxProps & {
  label: ReactNode;
};

const CustomCheckbox = ({ label, ...props }: CustomCheckboxProps) => (
  <StyledFormControlLabel
    label={label}
    control={<StyledCheckbox {...props} />}
  />
);

export default CustomCheckbox;
