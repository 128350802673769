export enum UserSubscriptionStatus {
  INIT = "INIT",
  PAID = "PAID",
  CANCELED = "CANCELED",
}

export enum CodeSubscriptionTimeUnit {
  DAY = "DAY",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export type Code = {
  id: number;
  code: string;
  name: string;
  price: number;
  subscriptionLength: number;
  subscriptionTimeUnit: CodeSubscriptionTimeUnit;
  maxUsers: number;
  expiresAt: string;
  createdAt: string;
  updatedAt: string;
};
