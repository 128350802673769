import { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { useParams } from "react-router-dom";

import Register from "../../components/connect/Register";
import Login from "../../components/connect/Login";

import "./Connect.css";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  title: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, title, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="connect-content">
          <h2 className="connect-title">{title}</h2>
          {children}
        </div>
      )}
    </div>
  );
}

const Connect = () => {
  const { type } = useParams();
  const isConnection = type === "se-connecter";

  // tabs state
  const tabs = [{ label: "S'inscrire" }, { label: "Se connecter" }];
  const [tab, setTab] = useState(isConnection ? 1 : 0);

  useEffect(() => {
    setTab(isConnection ? 1 : 0);
  }, [isConnection]);

  return (
    <div className="connect-main">
      <Tabs
        value={tab}
        onChange={(_, newValue) => setTab(newValue)}
        indicatorColor="primary"
        variant="fullWidth"
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>

      <div>
        <CustomTabPanel
          value={tab}
          index={0}
          title="Vous n'avez pas de compte ?"
        >
          <Register />
          <p>
            Vous avez déjà un compte ?{" "}
            <b onClick={() => setTab(1)}>Connectez-vous</b>
          </p>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1} title="Identifiez-vous">
          <Login />
          <p>
            Vous n'avez pas de compte ?{" "}
            <b onClick={() => setTab(0)}>Inscrivez-vous</b>
          </p>
        </CustomTabPanel>
      </div>
    </div>
  );
};

export default Connect;
