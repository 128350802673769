import { FormEvent } from "react";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";

import {
  setAuthUser,
  setUserRegistrationStep2,
} from "../../store/reducers/auth.slice";
import { useAppDispatch } from "../../store/hook";
import api, { setToken } from "../../utils/api/axios";
import { userSpecialitiesLabels } from "../../utils/user";
import { UserRegistration, UserSpeciality } from "../../types/user";
import { UserLogin } from "../../types/api/user";

const schema = z.object({
  speciality: z.string({ message: "Spécialité requise" }),
  establishment: z.string({ message: "Champ requis" }),
  service: z.string({ message: "Service requis" }),
  city: z.string({ message: "Ville requise" }),
});

type FormData = z.infer<typeof schema>;

interface Step2Props {
  userRegistration: Partial<UserRegistration> | null;
}

const Step1 = ({ userRegistration }: Step2Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { email, title, speciality, establishment, service, city } =
    userRegistration ?? {};

  if (!email) {
    navigate("/connexion/inscription");
  }

  if (!title) {
    navigate("/inscription/etape-1");
  }

  const { Field, Subscribe, handleSubmit } = useForm<
    FormData,
    typeof zodValidator
  >({
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      try {
        const userAuth = await api.post<UserLogin>("/auth/register", {
          ...userRegistration,
          ...value,
        });
        setToken(userAuth.data.token.token);
        dispatch(setAuthUser(userAuth.data));
        navigate("/inscription/etape-3");
      } catch (_) {}
    },
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit();
  };

  return (
    <div className="form">
      <form className="form" onSubmit={submit}>
        <div className="input-container">
          <Field
            name="speciality"
            defaultValue={speciality}
            validators={{ onChange: schema.shape.speciality }}
            children={(field) => (
              <>
                <p className="input-label">Spécialité*</p>
                <Select
                  id="select-service"
                  size="small"
                  required
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => {
                    field.handleChange(e.target.value as UserSpeciality);
                    dispatch(
                      setUserRegistrationStep2({
                        speciality: e.target.value as UserSpeciality,
                      })
                    );
                  }}
                >
                  {userSpecialitiesLabels.map((speciality) => (
                    <MenuItem key={speciality.value} value={speciality.value}>
                      {speciality.label}
                    </MenuItem>
                  ))}
                </Select>
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </>
            )}
          />
        </div>

        <div className="input-container">
          <Field
            name="establishment"
            defaultValue={establishment}
            validators={{ onChange: schema.shape.city }}
            children={(field) => (
              <>
                <p className="input-label">Établissement / Société*</p>
                <TextField
                  size="small"
                  id="establishment"
                  type="text"
                  fullWidth
                  required
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => {
                    field.handleChange(e.target.value);
                    dispatch(
                      setUserRegistrationStep2({
                        establishment: e.target.value,
                      })
                    );
                  }}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </>
            )}
          />
        </div>
        <div className="input-container">
          <Field
            name="service"
            defaultValue={service}
            validators={{ onChange: schema.shape.service }}
            children={(field) => (
              <>
                <p className="input-label">Service*</p>
                <TextField
                  size="small"
                  id="service"
                  type="text"
                  fullWidth
                  required
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => {
                    field.handleChange(e.target.value);
                    dispatch(
                      setUserRegistrationStep2({ service: e.target.value })
                    );
                  }}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </>
            )}
          />
        </div>

        <div className="input-container">
          <Field
            name="city"
            defaultValue={city}
            validators={{ onChange: schema.shape.city }}
            children={(field) => (
              <>
                <p className="input-label">Ville*</p>
                <TextField
                  size="small"
                  id="city"
                  type="text"
                  fullWidth
                  required
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => {
                    field.handleChange(e.target.value);
                    dispatch(
                      setUserRegistrationStep2({ city: e.target.value })
                    );
                  }}
                />
              </>
            )}
          />
        </div>

        <div className="registration-buttons-container">
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Revenir
          </Button>
          <Subscribe
            // @ts-ignore ignore build error
            selector={(state) => [state.canSubmit]}
            // @ts-ignore ignore build error
            children={([canSubmit]) => (
              <Button variant="contained" disabled={!canSubmit} type="submit">
                Suivant
              </Button>
            )}
          />
        </div>
      </form>
    </div>
  );
};

export default Step1;
