import { useEffect, useState } from "react";
import DOMPurify from "dompurify";

import api from "../../utils/api/axios";

import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";

import "./CustomPage.css";

interface CusotmPageProps {
  title: string;
  pageName: string;
  description?: string;
}

const CusotmPage = ({ title, pageName, description }: CusotmPageProps) => {
  const [data, setData] = useState<string>();

  const fetchData = async () => {
    try {
      const { data } = await api.get(`/page/${pageName}`);
      const sanitizedContent = DOMPurify.sanitize(data);
      setData(sanitizedContent);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page">
      <CustomBreadcrumbs links={[{ text: title }]} />

      <div className="page-header">
        <h1 className="page-title">{title}</h1>
        {description && <p className="page-description">{description}</p>}
      </div>
      <div dangerouslySetInnerHTML={{ __html: data || "" }} />
    </div>
  );
};

export default CusotmPage;
