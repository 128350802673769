import { CodeSubscriptionTimeUnit } from "../types/subscription";

export const codeSubscriptionTimeUnitLabels = [
  {
    label: "jour",
    labelPlural: "jours",
    labelUppercase: "Jour",
    labelUppercasePlural: "Jours",
    value: CodeSubscriptionTimeUnit.DAY,
  },
  {
    label: "mois",
    labelPlural: "mois",
    labelUppercase: "Mois",
    labelUppercasePlural: "Mois",
    value: CodeSubscriptionTimeUnit.MONTH,
  },
  {
    label: "an",
    labelPlural: "ans",
    labelUppercase: "An",
    labelUppercasePlural: "Ans",
    value: CodeSubscriptionTimeUnit.YEAR,
  },
];
