import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

import ArrowRight from "../assets/icons/arrows/right";
import tinyLogo from "../assets/logos/tiny-logo.svg";

export interface BreadcrumbsLink {
  to?: string;
  text: string;
}

interface CustomBreadcrumbsProps {
  links: BreadcrumbsLink[];
}

const CustomBreadcrumbs = ({ links }: CustomBreadcrumbsProps) => (
  <Breadcrumbs separator={<ArrowRight color="light-grey" />}>
    <Link to="/" className="breadcrumbs-link">
      <img src={tinyLogo} alt="ePOPI logo" />
    </Link>
    {links.map((item) =>
      item.to ? (
        <Link key={item.text} to={item.to} className="breadcrumbs-item link">
          {item.text}
        </Link>
      ) : (
        <p key={item.text} className="breadcrumbs-item text">
          {item.text}
        </p>
      )
    )}
  </Breadcrumbs>
);

export default CustomBreadcrumbs;
