import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { FormEvent } from "react";
import { z } from "zod";

import api from "../../utils/api/axios";
import { notifyApiError } from "../../utils/api/notifyApi";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { setUserRegistration } from "../../store/reducers/auth.slice";

const schema = z.object({
  email: z.string().email("Renseignez un email valide"),
  password: z
    .string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères"),
});

type FormData = z.infer<typeof schema>;

const Register = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.auth.userRegistration);
  const { email, password } = user ?? {};

  const { Field, Subscribe, handleSubmit } = useForm<
    FormData,
    typeof zodValidator
  >({
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      try {
        const { data } = await api.post("/auth/check-email", {
          email: value.email,
        });
        if (data.exists === true) throw new Error();
        else {
          dispatch(setUserRegistration(value));
          navigate("/inscription/etape-1");
        }
      } catch (_) {
        notifyApiError("Cet email est déjà utilisé");
      }
    },
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit();
  };

  return (
    <form className="form" onSubmit={(e) => submit(e)}>
      <p>
        Créez un compte et abonnez-vous à ePOPI pour 
        <u>33,00€ TTC par an</u>
      </p>
      <div className="input-container">
        <Field
          name="email"
          defaultValue={email ?? ""}
          validators={{ onChange: schema.shape.email }}
          children={(field) => (
            <>
              <p className="input-label">Email*</p>
              <TextField
                type="text"
                placeholder="Votre email"
                autoComplete="email"
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </>
          )}
        />
      </div>
      <div className="input-container">
        <Field
          name="password"
          defaultValue={password ?? ""}
          validators={{ onChange: schema.shape.password }}
          children={(field) => (
            <>
              <p className="input-label">Mot de passe*</p>
              <TextField
                type="password"
                placeholder="••••••••"
                autoComplete="current-password"
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </>
          )}
        />
      </div>

      <Subscribe
        // @ts-ignore ignore build error
        selector={(state) => [state.canSubmit, state.isSubmitting]}
        // @ts-ignore ignore build error
        children={([canSubmit]) => (
          <Button
            sx={{
              backgroundColor: "#000",
              color: "white",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
            variant="contained"
            disabled={!canSubmit}
            type="submit"
            size="large"
          >
            Créer mon compte ePOPI
          </Button>
        )}
      />
    </form>
  );
};

export default Register;
