export enum TitleMember {
  PROFESSOR_MALE = "PROFESSOR_MALE",
  PROFESSOR_FEMALE = "PROFESSOR_FEMALE",
  DOCTOR_MALE = "DOCTOR_MALE",
  DOCTOR_FEMALE = "DOCTOR_FEMALE",
  MR = "MR",
  MS = "MS",
}

export type ComityMember = {
  id: number;
  lastname: string;
  firstname: string;
  // siteName can have those values CMIT_MEMBER | COMITY_MEMBER | COORDINATOR
  siteName: string;
  title?: TitleMember;
  email: string;
  service?: string;
  establishment?: string;
  address?: string;
  phone?: string;
};

export type ReduceMembers = {
  comityMembers: ComityMember[];
  coordinators: ComityMember[];
};
