import team from "../../assets/icons/team.svg";
import award from "../../assets/icons/award.svg";
import light from "../../assets/icons/light.svg";

interface CardProps {
  icon: string;
  title: string;
  description: JSX.Element;
}

const Card = ({ icon, title, description }: CardProps) => (
  <div className="discover-card">
    <img className="discover-card__icon" src={icon} alt="icon" />
    <h3 className="discover-card__title">{title}</h3>
    <p className="discover-card__description">{description}</p>
  </div>
);

const DiscoverEpopi = () => (
  <div id="decouverte" className="discover content">
    <div className="discover__title">
      <h1 className="discover__title--main">ePOPI©</h1>
      <p className="discover__title--sub">
        ePOPI est l'outil numérique incontournable pour tout professionnel de
        santé impliqué dans le traitement des maladies infectieuses et
        tropicales
      </p>
    </div>

    <div className="discover__content">
      <Card
        icon={team}
        title="Collaboratif"
        description={
          <>
            ePOPI est un <b>ouvrage collectif</b> élaboré par des{" "}
            <b>experts nationaux</b> dans le domaine des maladies infectieuses
            et tropicales : enseignants - chercheurs, cliniciens, pharmaciens et
            microbiologistes.
          </>
        }
      />
      <Card
        icon={award}
        title="Expertise"
        description={
          <>
            <b>Guide pratique d'aide à la prescription, référence nationale</b>,
            ePOPI® couvre <b>l’ensemble des domaines de l’infectiologie</b> :
            des pathologies aux traitements, des agents infectieux (bactéries,
            virus, champignons, parasites) sans oublier la prophylaxie.
          </>
        }
      />
      <Card
        icon={light}
        title="Intuitif"
        description={
          <>
            Conçu comme un <b>guide d’aide à la prescription</b>, la navigation
            intuitive de ePOPI© facilite la recherche d’informations et permet
            de naviguer d’une fiche à l’autre en un clic.
          </>
        }
      />
    </div>
  </div>
);

export default DiscoverEpopi;
