import { Abbreviation } from "../types/api/abbreviations";

export const getChidrenLetterAndIndex = (abbreviations: Abbreviation[]) => {
  const letterDone: string[] = [];
  const lettersAndIndexes: [string, number][] = [];
  abbreviations.forEach((child, index) => {
    const nameFirstLetter = child.abbreviation[0].toUpperCase();
    if (!letterDone.includes(nameFirstLetter)) {
      letterDone.push(nameFirstLetter);
      lettersAndIndexes.push([nameFirstLetter, index]);
    }
  });

  return new Map(lettersAndIndexes);
};
