import { TitleMember } from "../types/comity";

export const getTitle = (title?: TitleMember) => {
  switch (title) {
    case TitleMember.PROFESSOR_MALE:
    case TitleMember.PROFESSOR_FEMALE:
      return "Pr. ";
    case TitleMember.DOCTOR_MALE:
    case TitleMember.DOCTOR_FEMALE:
      return "Dr. ";
    case TitleMember.MR:
      return "M. ";
    case TitleMember.MS:
      return "Mme. ";
    default:
      return "";
  }
};
