import { useEffect, useState } from "react";
import DOMPurify from "dompurify";

import Minus from "../../assets/icons/minus";
import Plus from "../../assets/icons/plus";

interface PartProps {
  defaultOpen?: boolean;
  name: string;
  content: string;
  color: string;
}

const Part = ({ defaultOpen, name, content, color }: PartProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const sanitizedContent = DOMPurify.sanitize(content);
  const uniquePlan = name === "(Partie unique générale)";

  useEffect(() => {
    if (defaultOpen === true) setIsOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <div
      className={[
        "sheet-part",
        !uniquePlan ? `border-bottom-${color}` : "",
      ].join(" ")}
    >
      {!uniquePlan && (
        <div
          className="sheet-part-title__container"
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className={["sheet-part-title", color].join(" ")}>{name}</p>
          {isOpen && <Minus color={color} />}
          {!isOpen && <Plus color={color} />}
        </div>
      )}
      {(isOpen || uniquePlan) && (
        <div
          className={`sheet-part__content li-${color}`}
          dangerouslySetInnerHTML={{ __html: sanitizedContent || "" }}
        />
      )}
    </div>
  );
};

export default Part;
