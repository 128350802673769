import axios, { InternalAxiosRequestConfig, isAxiosError } from "axios";

import store from "../../store/store";
import {
  setDisconnect,
  setHasNoNetwork,
} from "../../store/reducers/auth.slice";
import { notifyApiError } from "./notifyApi";

export const TOKEN_STORAGE_KEY = "token";

export const setToken = (newToken: string) => {
  localStorage.setItem(TOKEN_STORAGE_KEY, newToken);
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  store.dispatch(setDisconnect());
};

const tokenRequestSetup = (axiosConfig: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);

  axiosConfig.headers.Authorization = `Bearer ${token}`;

  return axiosConfig;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
api.interceptors.request.use(tokenRequestSetup);
api.interceptors.response.use(
  (response) => {
    store.dispatch(setHasNoNetwork(false));
    return response;
  },
  (error) => {
    if (isAxiosError(error)) {
      // network error handler
      if (error.code === "ERR_NETWORK") {
        store.dispatch(setHasNoNetwork(true));

        if (error.config?.method === "post") {
          notifyApiError(
            "Connexion au serveur impossible, vérifiez votre connexion internet"
          );
        }
        throw error;
      }

      notifyApiError(error.response?.data);

      const statusCode = error.response?.status;
      // Unauthorized error handler
      statusCode === 401 && removeToken();
    }
    throw error;
  }
);

export default api;
