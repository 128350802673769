import { get } from "idb-keyval";
import { GetAllDataCategory, GetAllDataSheet } from "../types/api/allDatas";
import { GetSheet, PlanPart, SheetPart } from "../types/api/sheet";
import { getCategoryParent, getMainCategory } from "./categories";

export const constructSheetFromStore = async (
  sheetId: number
): Promise<GetSheet | undefined> => {
  const sheet = await get<GetAllDataSheet>("sheet-" + sheetId);
  if (!sheet) return;
  const tinyCategory = await get<GetAllDataCategory>(
    "category-" + sheet.categoryId
  );
  if (!tinyCategory) return;

  const categoryWithParent = await getCategoryParent(tinyCategory);
  const mainCategory = getMainCategory(categoryWithParent);
  const tinyMainCategory = await get<GetAllDataCategory>(
    "category-" + mainCategory.id
  );
  if (!tinyMainCategory) return;

  const parts = tinyMainCategory.planParts.reduce(
    (acc, planPart) => {
      const sheetPart = sheet.sheetParts?.find((p) => p.planId === planPart.id);
      if (sheetPart && sheetPart.content !== null && planPart.isActive) {
        acc.push({ sheetPart, planPart });
      }
      return acc;
    },
    [] as {
      sheetPart: SheetPart;
      planPart: PlanPart;
    }[]
  );
  const sheetParts = parts.map((part) => part.sheetPart);
  const planParts = parts.map((part) => part.planPart);

  return {
    sheet: {
      id: sheet.id,
      name: sheet.name,
      updatedAt: sheet.updatedAt,
    },
    mainCategory,
    sheetParts,
    planParts,
    categories: categoryWithParent,
    hasAllowedAccess: true,
  };
};
