import { useEffect, useState } from "react";

import api from "../../utils/api/axios";
import { ComityMember } from "../../types/comity";

import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";
import Section from "../../components/comity/Section";

import "../comity/Comity.css";

const CmitMembers = () => {
  const title = "Membres du CMIT";

  const [cmitMembers, setCmitMembers] = useState<ComityMember[]>();

  const fetchData = async () => {
    try {
      const { data } = await api.get<ComityMember[]>(`/comity-members`);

      const cmitMembersFiltered = data.filter((member) => {
        const siteNameSplit = member.siteName.split("|");
        return siteNameSplit.includes("CMIT_MEMBER");
      });

      setCmitMembers(cmitMembersFiltered);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page">
      <CustomBreadcrumbs links={[{ text: title }]} />
      <h1 className="page-title">{title}</h1>

      <div className="page-sections">
        <Section members={cmitMembers} />
      </div>
    </div>
  );
};

export default CmitMembers;
