import { getTitle } from "../../utils/comityMember";
import { ComityMember } from "../../types/comity";

interface MembersProps extends ComityMember {}

const Members = ({
  firstname,
  lastname,
  email,
  title,
  service,
  establishment,
  address,
  phone,
}: MembersProps) => (
  <div className="section-member">
    <p className="section-member-title">
      {getTitle(title)}
      {firstname} {lastname}
    </p>
    <p className="section-member-text">
      {service ? `${service} - ` : null}
      {establishment}
    </p>
    <p className="section-member-text">{address}</p>
    {phone && <p className="section-member-text">Tel. : {phone}</p>}
    {email && <p className="section-member-text">Mail : {email}</p>}
  </div>
);

export default Members;
