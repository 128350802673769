import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, useMediaQuery, useTheme } from "@mui/material";

import ArrowLeft from "../../assets/icons/arrows/left";

export interface Cta {
  children: React.ReactNode;
  onClick: () => void;
  variant?: "contained" | "outlined";
  disabled?: boolean;
  type?: "button" | "submit";
}

interface SettingsSectionProps {
  title: string;
  children: React.ReactNode;
  noGoBack?: boolean;
  cta?: Cta[];
}

const SettingsSection = ({
  title,
  children,
  noGoBack,
  cta,
}: SettingsSectionProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="settings-section__container">
      <div className="settings-section__header">
        <div
          className={`settings-section__header-title--container ${
            !noGoBack ? "has-arrow" : ""
          }`}
        >
          {isSmallScreen && !noGoBack && (
            <IconButton
              onClick={() => navigate("/mon-compte", { replace: true })}
            >
              <ArrowLeft color="navy" />
            </IconButton>
          )}
          <h4 className="settings-section__header-title">{title}</h4>
        </div>
        {cta && cta.length ? (
          <div className="settings-section__header-cta--container">
            {cta.map((ctaItem, index) => (
              <Button key={index} {...ctaItem} size="large" />
            ))}
          </div>
        ) : null}
      </div>

      <div className="settings-section__content">{children}</div>

      {cta && cta.length ? (
        <div className="settings-section__content-cta--container">
          {cta?.map((ctaItem, index) => (
            <Button key={index} {...ctaItem} size="large" />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default SettingsSection;
