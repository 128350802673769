import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAppSelector } from "../../store/hook";

import ArrowRight from "../../assets/icons/arrows/right";
import settingsItemIcon from "../../assets/icons/settings-item.svg";

import MySubscription from "../../components/settings/MySubscription";
import MyInfos from "../../components/settings/MyInfos";
import Invoices from "../../components/settings/Invoices";

import "./Settings.css";

const Settings = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const tabs = [
    { url: "abonnement", name: "Mon abonnement" },
    { url: "mes-informations", name: "Mes informations" },
    { url: "facturation", name: "Facturation" },
  ];
  const { tab } = useParams();
  const currentTab =
    tab === "abonnement"
      ? 0
      : tab === "mes-informations"
      ? 1
      : tab === "facturation"
      ? 2
      : undefined;
  const [activeTab, setActiveTab] = useState(currentTab);

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  if (!user || user.role === "ESTABLISHMENT") {
    return (
      <h1 onClick={() => navigate("/", { replace: true })}>
        Section indisponible
      </h1>
    );
  }

  return (
    <div className="settings-main-container">
      <p>Bonjour {user.firstname}</p>

      <div className="settings-container">
        {(activeTab === undefined || !isSmallScreen) && (
          <div className="settings-menu">
            <List
              sx={{
                padding: 0,
                border: "1px solid var(--grey-200)",
              }}
            >
              {tabs.map((tab, index) => (
                <ListItem
                  key={tab.url}
                  disablePadding
                  sx={{
                    borderTop: index > 0 ? "1px solid var(--grey-200)" : "none",
                    backgroundColor:
                      activeTab === index ? "var(--grey-100)" : "transparent",
                  }}
                  onClick={() => navigate(`/mon-compte/${tab.url}`)}
                >
                  <ListItemButton sx={{ gap: "8px" }}>
                    <img src={settingsItemIcon} alt="Settings" />
                    <ListItemText primary={tab.name} />
                    {isSmallScreen && <ArrowRight />}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>
        )}

        {(activeTab !== undefined || !isSmallScreen) && (
          <div className="settings-content">
            {activeTab === 0 && <MySubscription />}
            {activeTab === 1 && <MyInfos />}
            {activeTab === 2 && <Invoices />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
