import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import api from "../../utils/api/axios";
import {
  getOldCategoryRedirection,
  getOldSheetRedirection,
} from "../../utils/legacy";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { setAuthUser } from "../../store/reducers/auth.slice";
import { UserLogin } from "../../types/api/user";

import Hero from "../../components/home/Hero";
import DiscoverEpopi from "../../components/home/DiscoverEpopi";
import HomeCategories from "../../components/home/HomeCategories";
import RecentSheets from "../../components/home/RecentSheets";
import EpopiOnSmartphone from "../../components/home/EpopiOnSmartphone";

import "./Home.css";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const auth = useAppSelector((state) => state.auth);
  const { isAuth, hasNoNetwork } = auth;

  const [searchParams] = useSearchParams();
  const legacySheetId = searchParams.get("id");
  const legacyCatId = searchParams.get("cat_id");

  const getMe = async () => {
    try {
      const { data } = await api.get<UserLogin>("/auth/me");
      if (data) {
        dispatch(setAuthUser(data));
      }
    } catch (_) {}
  };

  const getRedirection = async (
    legacyCategoryId: string,
    legacySheetId: string | null
  ) => {
    try {
      if (legacySheetId) {
        const sheetId = await getOldSheetRedirection(
          legacySheetId,
          legacyCategoryId
        );
        if (sheetId) navigate(`/fiche/${sheetId}`);
      } else {
        const categoryId = await getOldCategoryRedirection(legacyCategoryId);
        if (categoryId) navigate(`/categorie/${categoryId}`);
      }
    } catch (_) {}
  };

  useEffect(() => {
    if (isAuth && !hasNoNetwork) getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  useEffect(() => {
    if (legacyCatId) getRedirection(legacyCatId, legacySheetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legacySheetId, legacyCatId]);

  return (
    <>
      <Hero isAuth={isAuth} />
      {!isAuth && <DiscoverEpopi />}
      <HomeCategories isAuth={isAuth} />
      {isAuth && <RecentSheets />}
      {isAuth && <EpopiOnSmartphone />}
    </>
  );
};

export default Home;
